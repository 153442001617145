import React from "react";
import style from "../styles/input.module.scss";
import { UseFormRegisterReturn } from "react-hook-form";

interface InputType<T extends string> {
  title?: string;
  register: UseFormRegisterReturn<T>;
  placeholder?: string;
  error?: string;
  isPassword?: boolean;
  ele?: JSX.Element;
  disabled?: boolean;
  id?: string;
}

export default function InputItem<T extends string>({
  title,
  register,
  placeholder,
  error,
  isPassword,
  ele,
  disabled,
  id,
}: InputType<T>) {
  const isShow = isPassword;

  return (
    <div className={style.inputContainer}>
      {title && <div className={style.title}>{title}</div>}
      <div className={style.inputForm}>
        <input
          disabled={disabled}
          type={isShow ? "password" : "text"}
          className={error ? `${style.input} ${style.error}` : style.input}
          {...register}
          placeholder={placeholder ?? ""}
          autoComplete="on"
        />
        {ele && <div className={style.validate}>{ele}</div>}
      </div>
      {error && <p className={style.errMsg}>{error}</p>}
    </div>
  );
}
