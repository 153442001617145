import { ChangeEvent } from "react";
import style from "../styles/toggle-switch.module.scss";

interface Props {
  isChecked: boolean;
  onToggle: (disabled: boolean) => void;
}

export function ToggleSwitch({ isChecked, onToggle }: Props) {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    onToggle(!isChecked);
  };

  return (
    <>
      <div className={style.switch}>
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleOnChange}
        />
        <div className={`${style.slider} ${style.round}`}></div>
      </div>
    </>
  );
}
