import { useEffect, useRef, useState } from "react";
import { Time } from "./timeRangeSelect";
import { useClickOutside } from "../util/hook/useClickOutsde";
import ArrowDown from "../svg/arrowDown";
import ArrowUp from "../svg/arrowUp";
import style from "../styles/dropdown-time-select.module.scss";

interface DropDownTimeSelectProps {
  type: "start" | "stop";
  option?: Time;
  options: moment.Moment[];
  onSelectOption: (option?: Time) => void;
  disabled?: boolean;
  updateAvailableTimes?: () => void;
  parentRef?: React.RefObject<HTMLDivElement> | null;
  modal?: boolean;
}

export function DropDownTimeSelect({
  type,
  option,
  options,
  onSelectOption,
  disabled = false,
  updateAvailableTimes,
  parentRef = null,
  modal = false,
}: DropDownTimeSelectProps) {
  const menuRef = useRef<HTMLDivElement>(null);
  const [collapsed, setCollpased] = useClickOutside(menuRef);
  const [selectedOption, setSelectedOption] = useState<Time | undefined>(
    undefined
  );
  const [isMenuDropDown, setIsMenuDropDown] = useState<boolean>(true);

  const checkIsDropDownMenuDirection = () => {
    const EXPANDED_MENU_MAX_HEIGHT = 256;
    if (parentRef) {
      const top = parentRef.current?.getBoundingClientRect().top ?? 0;
      const height = parentRef.current?.offsetHeight ?? 0;
      const bottom = window.innerHeight - Math.floor(top) - Math.floor(height);
      const isMenuDropDown = bottom <= EXPANDED_MENU_MAX_HEIGHT;
      setIsMenuDropDown(!isMenuDropDown);
    }
  };

  const handleOnExpandMenu = () => {
    checkIsDropDownMenuDirection();
    setCollpased(!collapsed);
    type === "start" && updateAvailableTimes && updateAvailableTimes();
  };

  const handleOnSelectOption = (option?: Time) => {
    setSelectedOption(option);
    onSelectOption(option);
    setCollpased(true);
  };

  useEffect(() => {
    setSelectedOption(option);
  }, [option]);

  const getTimeFormat = (time?: moment.Moment) => {
    return time ? time.format("A h:mm") : "";
  };

  const disabledColor = disabled ? "#616161" : "#FFFFFF";

  return (
    <div
      ref={menuRef}
      className={style.container}
    >
      <div
        className={`${style.selected} ${modal ? style.modal : ""}`}
        onClick={handleOnExpandMenu}
        style={{
          pointerEvents: disabled ? "none" : "auto",
          fontSize: modal ? 17.5 : 14,
        }}
      >
        <div
          className={`${style.title} ${modal ? style.modal : ""}`}
          style={{
            color: disabledColor,
          }}
        >
          {getTimeFormat(selectedOption)}
        </div>
        <div className={`${style.arrowIcon} ${modal ? style.modal : ""}`}>
          {collapsed ? (
            <ArrowDown fill={disabledColor} />
          ) : (
            <ArrowUp fill={disabledColor} />
          )}
        </div>
      </div>
      {!collapsed ? (
        <div
          className={`${style.expanded} ${modal ? style.modal : ""}`}
          style={{
            top: isMenuDropDown ? (modal ? 52.75 : 43.25) : -262,
          }}
        >
          {options.map((option) => {
            return (
              <div
                key={option.format()}
                onClick={() => handleOnSelectOption(option)}
                className={style.option}
              >
                {getTimeFormat(option)}
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
