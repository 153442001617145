import { useEffect } from "react";
import { Calendar, SlotInfo, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../styles/custom-calendar.scss";
import moment from "moment";
import "moment/locale/ko";
import { EventAreaScheduleModel, GlobalAreaSchedulModel } from "../util/Model";

export default function BigCalendar({
  onChangeMonth,
  onSelectDay,
  events,
  global,
  calDate,
}: {
  onChangeMonth: (date: any) => void;
  onSelectDay: (date: any, events?: EventAreaScheduleModel[]) => void;
  events: EventAreaScheduleModel[];
  global: GlobalAreaSchedulModel[];
  calDate: Date;
}) {
  moment.locale("ko-KR");

  const filterEvent = events.reduce((uni, item) => {
    const isDouble = uni.find(
      (e: EventAreaScheduleModel) => e.date === item.date
    );
    if (!isDouble) uni.push(item);
    return uni;
  }, [] as EventAreaScheduleModel[]);

  const localizer = momentLocalizer(moment);

  const onSelectSlot = async (slot: SlotInfo) => {
    if (slot.slots?.length > 1) return;
    const start = new Date(slot.start);
    const end = new Date(slot.end);
    const today_event = events.filter(
      (i) => new Date(i.date) >= start && new Date(i.date) < end
    );
    if (today_event.length > 0) {
      onSelectDay(slot, today_event);
    } else {
      onSelectDay(slot);
    }
  };

  const onSelectEvent = (event: any) => {
    const end = moment(event.end).add(1, "days");
    const res = {
      ...event,
      end: end.toString(),
    };
    console.log(res);
    onSelectSlot(res);
  };

  const handleDateHover = (
    date: Date,
    view: string,
    e: React.MouseEvent<HTMLElement>
  ) => {
    // 호버한 날짜에 대한 커스텀 처리를 수행합니다.
    // 여기에서는 커스텀 CSS 클래스를 추가합니다.
    const hoveredDate = e.target as Element;
    hoveredDate.classList.add("custom-hovered-date");
  };

  const handleDateHoverEnd = (
    date: Date,
    view: string,
    e: React.MouseEvent<HTMLElement>
  ) => {
    // 호버를 떠날 때 커스텀 처리를 수행합니다.
    // 여기에서는 커스텀 CSS 클래스를 제거합니다.
    const hoveredDate = e.target as Element;
    hoveredDate.classList.remove("custom-hovered-date");
  };

  useEffect(() => {
    onChangeMonth(calDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div style={{ width: "90%", height: "90%" }}>
      <Calendar
        onNavigate={(date, view, action) => {
          onChangeMonth(date);
        }}
        longPressThreshold={0}
        defaultDate={calDate}
        events={
          filterEvent.length > 0
            ? filterEvent.map((event) => ({
                allDay: true,
                title: "수정된 이벤트",
                start: event.date,
                end: event.date,
              }))
            : undefined
        }
        onView={(view) => {
          return;
        }}
        culture="ko-KR"
        localizer={localizer}
        onSelectSlot={onSelectSlot}
        onSelectEvent={onSelectEvent}
        selectable
        view="month"
        components={{
          toolbar: Toolbar,
          event: EventComponent,
        }}
        dayPropGetter={(date) => {
          // 날짜를 커스텀하려면 dayPropGetter를 사용합니다.
          return {
            onMouseOver: (e) => handleDateHover(date, "month", e),
            onMouseOut: (e) => handleDateHoverEnd(date, "month", e),
          };
        }}
      />
    </div>
  );
}

function Toolbar(props: any) {
  const navigate = (action: any) => {
    props.onNavigate(action);
  };
  return (
    <div className="rbc-toolbar">
      <span className="rbc-btn-group">
        <div
          className="rbc-btn-navigate"
          onClick={navigate.bind(null, "PREV")}
        >
          <img
            src="/images/cal_arrowx3.png"
            alt="arrow"
            style={{ transform: "rotate(180deg)" }}
          />
        </div>
        <span className="rbc-toolbar-label">
          {`${props.date.getFullYear()}년 `}
          <strong>{`${props.date.getMonth() + 1}월`}</strong>
        </span>
        <div
          className="rbc-btn-navigate"
          onClick={navigate.bind(null, "NEXT")}
        >
          <img
            src="/images/cal_arrowx3.png"
            alt="arrow"
          />
        </div>
      </span>
    </div>
  );
}

function EventComponent(props: any) {
  return (
    <>
      <div className="rbc-event-check" />
      <span>수정된 이벤트</span>
    </>
  );
}
