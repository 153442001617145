interface Props {
  fill?: string;
}

export function PlayIcon({ fill = "white" }: Props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.0605 9.47884L7.8272 3.77907C7.7189 3.70434 7.5935 3.66634 7.46683 3.66634C7.36613 3.66634 7.26543 3.69041 7.17296 3.73854C6.96396 3.8481 6.8335 4.06406 6.8335 4.29965L6.8335 15.6992C6.8335 15.9348 6.96396 16.1507 7.17296 16.2603C7.38133 16.3705 7.6334 16.354 7.8272 16.2204L16.0605 10.5206C16.2315 10.4016 16.3335 10.2071 16.3335 9.99942C16.3335 9.7917 16.2315 9.59727 16.0605 9.47884Z"
        fill={fill}
      />
      <defs>
        <linearGradient
          id="paint0_linear_1975_14418"
          x1="8.7335"
          y1="15.6205"
          x2="16.5845"
          y2="6.72416"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#59C1FF" />
          <stop
            offset="1"
            stopColor="#009FFF"
          />
        </linearGradient>
      </defs>
    </svg>
  );
}
