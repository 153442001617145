import style from "../styles/toggle-button.module.scss";

interface Props {
  active: boolean;
  disabled?: boolean;
  activeStyle?: any;
  defaultStyle?: any;
  disabledStyle?: any;
  activeTextStyle?: any;
  defaultTextStyle?: any;
  disabledTextStyle?: any;
  W?: number | string;
  H?: number | string;
  text?: string;
  onClick: () => void;
  icon?: () => JSX.Element;
}

export function ToggleButton({
  active,
  disabled = false,
  activeStyle,
  defaultStyle,
  disabledStyle,
  activeTextStyle,
  defaultTextStyle,
  disabledTextStyle,
  W,
  H,
  text,
  onClick,
  icon,
}: Props) {
  return (
    <div
      className={style.button}
      onClick={onClick}
      style={{
        width: W ?? 38,
        height: H ?? 38,
        pointerEvents: disabled ? "none" : "auto",
        ...{
          ...(disabled ? disabledStyle : active ? activeStyle : defaultStyle),
        },
      }}
    >
      <div className={style.titleContainer}>
        {icon ? <div className={style.icon}>{icon()}</div> : <></>}
        {text ? (
          <div
            className={style.title}
            style={{
              fontSize: 14,
              ...{
                ...(disabled
                  ? disabledTextStyle
                  : active
                  ? activeTextStyle
                  : defaultTextStyle),
              },
            }}
          >
            {text}
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
