import { useEffect } from "react";
import style from "../styles/barrier.module.scss";
import { LockIcon } from "../svg/lock";
import useLockScreen from "../util/hook/useLockScreen";

export default function Barrier() {
  const { isScreenLockMode, indicateLock, isIndicatingLock } = useLockScreen();

  const onClickScreen = () => !isIndicatingLock && handleOnIndicateLock();

  const handleOnIndicateLock = () => {
    indicateLock(true);
  };

  useEffect(() => {
    if (isScreenLockMode) {
      handleOnIndicateLock();
    }
  }, [isScreenLockMode]);

  if (!isScreenLockMode) {
    return <></>;
  }

  return (
    <div
      className={style.barrier}
      style={{
        backgroundColor: !isIndicatingLock ? "transparent" : "rgba(0,0,0,0.5)",
      }}
      onClick={onClickScreen}
    >
      {isIndicatingLock && (
        <div className={style.notification}>
          <LockIcon />
          <span>잠금모드 스위치를 눌러 잠금을 해제하세요.</span>
        </div>
      )}
    </div>
  );
}
