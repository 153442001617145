/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { Area, EventAreaSchedule } from "../type/api.type";
import { cloneDeep, isEqual } from "lodash";
import { GlobalAreaSchedulModel } from "../util/Model";
import style from "../styles/eventSchedule.module.scss";
import { Button } from "./button";
import { ToggleSwitch } from "./toggleSwitch";
import DropDownMenu from "./dropDownMenu";
import { Theme } from "../pages/main";
import { FilterIcon } from "../svg/filter";
import { TimeRange, TimeRangeSelect } from "./timeRangeSelect";
import { useEffect, useRef, useState } from "react";
import Return from "../svg/return";
import { ToggleButton } from "./toggleButton";
import CalIcon2 from "../svg/calendar2";
import { TIME_FULL_FORMAT, getMomentString } from "../util/helper";
import { useQueryClient } from "@tanstack/react-query";
import useGlobalModal from "../util/hook/useGlobalModal";

export default function EventScheduleCreate({
  date,
  globals,
  area,
  themes,
  onSave,
  onBack,
  setisUpdated,
  isUdated,
  setLoading,
}: {
  date: string;
  globals: GlobalAreaSchedulModel[];
  area: Area[];
  themes: Theme[];
  onSave: (events: EventAreaSchedule[], date: string) => Promise<void>;
  onBack: (date: string) => void;
  setisUpdated: (value: boolean) => void;
  isUdated: boolean;
  setLoading: (value: boolean) => void;
}) {
  const queryClient = useQueryClient();
  const { openModal } = useGlobalModal();

  const [newSchedule, setNewSchedule] = useState<EventAreaSchedule[]>([]);
  const [org, setOrg] = useState<EventAreaSchedule[]>([]);

  const onSaveCreate = async () => {
    setLoading(true);
    setisUpdated(false);
    await onSave(newSchedule, date);
    queryClient.invalidateQueries({
      queryKey: ["daily_events", moment(date).format("YYYY.MM")],
    });
    setLoading(false);
    onBack(date);
  };

  const onSetting = (data: EventAreaSchedule, i: number) => {
    const _new = cloneDeep(newSchedule);
    _new.splice(i, 1);
    _new.splice(i, 0, data);
    setNewSchedule(_new);
  };

  const onBackToCalendar = () => {
    if (isUdated) {
      openModal({
        title: "변경된 내용을 저장하지 않았어요.",
        content: "페이지를 나가게 되면 저장하지 않은 정보를 잃게 됩니다.",
        onConfirm: () => {
          onBack(date);
          setisUpdated(false);
        },
        onCancel: () => {},
      });
    } else {
      onBack(date);
    }
  };

  useEffect(() => {
    const events: EventAreaSchedule[] = [];
    globals.forEach((global) => {
      const org_start_at = moment(global.start_at);
      const org_stop_at = moment(global.stop_at);
      const event = {
        date: moment(date).format(TIME_FULL_FORMAT),
        theme: [global.theme[0].id],
        start_at: getMomentString(
          org_start_at.hour(),
          org_start_at.minute(),
          org_start_at.second(),
          TIME_FULL_FORMAT
        ),
        stop_at: getMomentString(
          org_stop_at.hour(),
          org_stop_at.minute(),
          org_stop_at.second(),
          TIME_FULL_FORMAT
        ),
        area: global.area,
        disabled: global.disabled,
      } as EventAreaSchedule;
      events.push(event);
    });
    events.sort(
      (a, b) =>
        area.find((k) => k.id === a.area)!.order -
        area.find((k) => k.id === b.area)!.order
    );
    setOrg(cloneDeep(events));
    setNewSchedule(cloneDeep(events));
  }, []);

  useEffect(() => {
    if (!isEqual(newSchedule, org)) setisUpdated(true);
    else setisUpdated(false);
  }, [newSchedule]);

  return (
    <div className={style.specialSetting}>
      <div className={style.content}>
        <div className={style.setting}>
          <div className={style.calendar}>
            <CalIcon2 />
            <strong>
              {moment(date).format("YYYY[년] MM[월] DD[일] (dd)")}
            </strong>
          </div>
          <ToggleButton
            text="설정 복귀하기"
            activeStyle={{
              border: "1px solid #0096FF",
              backgroundColor: "#343434",
              color: "#0096FF",
            }}
            defaultStyle={{
              border: "1px solid #757575",
              backgroundColor: "#343434",
              color: "#757575",
            }}
            onClick={() => {
              setNewSchedule(cloneDeep(org));
            }}
            icon={() => (
              <Return
                fill={!isEqual(newSchedule, org) ? "#0096FF" : "#757575"}
              />
            )}
            active={!isEqual(newSchedule, org)}
            W={140}
            H={35}
          />
        </div>
        <div className={style.schedules}>
          {newSchedule.map((e, i) => (
            <ItemSetting
              key={`special item ${i}`}
              info={e}
              themes={themes}
              area={area}
              onSetting={(data) => onSetting(data, i)}
            />
          ))}
        </div>
      </div>
      <div className={style.bottom}>
        <ToggleButton
          text="전체일정으로 돌아가기"
          activeStyle={{
            textDecoration: "underline",
            backgroundColor: "#343434",
            color: "#0096FF",
          }}
          onClick={onBackToCalendar}
          active={true}
          W={140}
          H={35}
        />
        <Button
          active={isUdated}
          type={"confirm"}
          onClick={onSaveCreate}
          confirmText={"저장"}
        />
      </div>
    </div>
  );
}

function ItemSetting({
  info,
  themes,
  area,
  onSetting,
}: {
  info: EventAreaSchedule;
  themes: Theme[];
  area: Area[];
  onSetting: (event: EventAreaSchedule) => void;
}) {
  const scheduleItemRef = useRef<HTMLDivElement | null>(null);

  const [timeRange, setTimeRange] = useState<TimeRange>({
    area: undefined,
    startAt: undefined,
    stopAt: undefined,
    disabled: false,
  });

  const onToggleSwitch = (e: boolean) => {
    info.disabled = e;
    onSetting(info);
  };

  const onDropDown = (e: { id: number; title: string; value: string }) => {
    info.theme = [e.id];
    onSetting(info);
  };

  const onSelectStartTime = (
    startAt?: moment.Moment,
    stopAt?: moment.Moment
  ) => {
    info.start_at = startAt!.format(TIME_FULL_FORMAT);
    info.stop_at = stopAt!.format(TIME_FULL_FORMAT);
    onSetting(info);
  };

  const onSelectEndTime = (option?: moment.Moment) => {
    if (option) {
      info.stop_at = option.format(TIME_FULL_FORMAT);
      onSetting(info);
    }
  };

  useEffect(() => {
    setTimeRange({
      startAt: moment(info.start_at),
      stopAt: moment(info.stop_at),
      disabled: false,
    });
  }, [info]);

  return (
    <div
      className={style.schedule}
      ref={scheduleItemRef}
    >
      <div className={style.item1}>
        <ToggleSwitch
          isChecked={!info.disabled}
          onToggle={onToggleSwitch}
        />
        <div>{area.find((a) => a.id === info.area)?.title}</div>
        <DropDownMenu
          currOption={themes.find((t) => t.id === info.theme[0]) as any}
          options={themes}
          onSelectOption={onDropDown}
          disabled={info.disabled}
          icon={() => <FilterIcon fill={info.disabled ? "#616161" : "white"} />}
          parentRef={scheduleItemRef}
        />
      </div>
      <TimeRangeSelect
        rangeType={"all"}
        timeRange={timeRange}
        disabled={info.disabled}
        onSelectStartOption={onSelectStartTime}
        onSelectEndOption={onSelectEndTime}
        parentRef={scheduleItemRef}
      />
    </div>
  );
}
