import { useController } from "juice-frontend-core";
import Input from "../components/input";
import { useLoginForm } from "../form/login.form";
import { AccountController } from "../util/Controller";
import { Link, useNavigate } from "react-router-dom";
import style from "../styles/login.module.scss";
import useGlobalModal from "../util/hook/useGlobalModal";

export default function Login() {
  const ctrl = useController<AccountController>(AccountController);
  const { openModal } = useGlobalModal();
  const navigate = useNavigate();

  const {
    f: {
      handleSubmit,
      formState: { errors, isValid },
    },
    r,
  } = useLoginForm();

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      await ctrl.login(data.username, data.password);
      navigate("/main", { replace: true });
    } catch (err) {
      // 모달 생성
      switch (err) {
        case "no user":
        case "wrong pw":
          openModal({
            title: "로그인 정보가 유효하지 않습니다.",
            content:
              "등록되지 않은 이메일 이거나 이메일 혹은 비밀번호를 잘못\n입력했습니다. 다시한번 확인해주시기 바랍니다.",
          });
          break;
        case "no account":
          openModal({
            title: "유저 아님",
            content: "사용 가능한 유저가 아닙니다.",
          });
          break;
        case "not active":
          openModal({
            title: "인증 받지 않은 회원입니다.",
            content: "해당 이메일로 회원가입을 먼저 진행해 주세요.",
          });
          break;
        default:
          openModal({
            title: "로그인 오류",
            content:
              "일시적인 오류로 로그인을 할 수 없습니다.\n잠시 후 다시 이용해 주세요.",
          });
          break;
      }
    }
  });

  return (
    <div className={style.loginContainer}>
      <form
        className={style.loginForm}
        onSubmit={onSubmit}
      >
        <h1 className={style.logo}>로그인</h1>
        <Input
          title="이메일"
          register={r.username}
          placeholder="이메일을 입력하세요"
          error={errors.username?.message}
        />
        <Input
          title="비밀번호"
          register={r.password}
          placeholder="비밀번호를 입력하세요"
          error={errors.password?.message}
          isPassword={true}
        />
        <button
          className={style.btn}
          type="submit"
          style={{
            backgroundColor: isValid ? "#0096FF" : "#424242",
          }}
        >
          로그인
        </button>
        <div className={style.more}>
          <Link to={"/password"}>비밀번호 변경</Link>
          <Link to={"/register"}>회원가입</Link>
        </div>
      </form>
    </div>
  );
}
