import moment from "moment";
import { GlobalAreaSchedulModel } from "../util/Model";
import DropDownMenu from "./dropDownMenu";
import { Theme } from "../pages/main";
import style from "../styles/globalSetting.module.scss";
import { TimeRange, TimeRangeSelect } from "./timeRangeSelect";
import { ToggleSwitch } from "./toggleSwitch";
import { useEffect, useRef, useState } from "react";
import { Area } from "../type/api.type";
import { cloneDeep, isEqual } from "lodash";
import { Button } from "./button";
import { FilterIcon } from "../svg/filter";
import { TIME_FULL_FORMAT, translateDay } from "../util/helper";

type GlobalType = {
  theme: number[];
  id: number;
  day: string;
  volume: number;
  start_at: string;
  stop_at: string;
  disabled: boolean;
  area: number;
};

export default function GlobalSetting({
  globals,
  theme_seg,
  area,
  onScheduleChange,
  setIsUpdated,
}: {
  globals: GlobalAreaSchedulModel[];
  theme_seg: Theme[];
  area: Area;
  onScheduleChange: (area: number, schedules: any[]) => void;
  setIsUpdated: (value: boolean) => void;
}) {
  const org = globals.map((i) => {
    return {
      ...i,
      theme: i.theme.map((g) => g.id),
    };
  });
  const [updateItems, setUpdateItems] = useState<any[]>([]);

  type GlobalKeys = keyof (typeof org)[0];
  const onUpdate = (id: number, type: GlobalKeys, data: any) => {
    const itemIndex = updateItems.findIndex((item) => item.id === id);
    if (itemIndex !== -1) {
      updateItems[itemIndex] = { ...updateItems[itemIndex], [type]: data };
    } else {
      updateItems.push({ id, [type]: data });
    }
    setUpdateItems([...updateItems]);
  };

  useEffect(() => {
    if (!isEqual(updateItems, org)) {
      setIsUpdated(true);
    } else {
      setIsUpdated(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateItems]);

  useEffect(() => {
    const org = globals.map((i) => {
      return {
        ...i,
        theme: i.theme.map((g) => g.id),
      };
    });
    setUpdateItems(cloneDeep(org));
  }, [globals]);

  return (
    <div className={style.globalSetting}>
      <div className={style.content}>
        <strong>{area.title}</strong>
        <div className={style.schedules}>
          {updateItems.map((w, i) => (
            <ItemSetting<GlobalKeys>
              key={`item: ${i}`}
              info={w}
              onUpdate={onUpdate}
              theme_seg={theme_seg}
            />
          ))}
        </div>
      </div>
      <div className={style.bottom}>
        <Button
          type={"confirm"}
          active={!isEqual(org, updateItems)}
          onClick={() => {
            if (!isEqual(org, updateItems))
              onScheduleChange(area.id, updateItems);
          }}
          confirmText={"저장"}
        />
      </div>
    </div>
  );
}

function ItemSetting<T>({
  info,
  onUpdate,
  theme_seg,
}: {
  info: GlobalType;
  onUpdate: (id: number, type: T, data: any) => void;
  theme_seg: Theme[];
}) {
  const scheduleItemRef = useRef<HTMLDivElement | null>(null);

  const [timeRange, setTimeRange] = useState<TimeRange>({
    area: undefined,
    startAt: undefined,
    stopAt: undefined,
    disabled: false,
  });

  const onToggleSwitch = (e: boolean) => {
    onUpdate(info.id, "disabled" as T, e);
  };

  const onDropDown = (e: { id: number; title: string; value: string }) =>
    onUpdate(info.id, "theme" as T, [e.id]);

  const onStartTimeSetting = (
    startAt?: moment.Moment,
    stopAt?: moment.Moment
  ) => {
    onUpdate(info.id, "start_at" as T, startAt!.format(TIME_FULL_FORMAT));
    onUpdate(info.id, "stop_at" as T, stopAt!.format(TIME_FULL_FORMAT));
  };

  const onStopTimeSetting = (stopAt?: moment.Moment) => {
    onUpdate(info.id, "stop_at" as T, stopAt!.format(TIME_FULL_FORMAT));
  };

  useEffect(() => {
    setTimeRange({
      startAt: moment(info.start_at),
      stopAt: moment(info.stop_at),
      disabled: info.disabled,
    });
  }, [info]);

  return (
    <div
      className={style.schedule}
      ref={scheduleItemRef}
    >
      <div className={style.item1}>
        <ToggleSwitch
          isChecked={!info.disabled}
          onToggle={onToggleSwitch}
        />
        <div>{translateDay(info.day)}</div>
        <DropDownMenu
          currOption={theme_seg.find((t) => t.id === info.theme[0]) as any}
          options={theme_seg}
          onSelectOption={onDropDown}
          disabled={info.disabled}
          icon={() => <FilterIcon fill={info.disabled ? "#616161" : "white"} />}
          parentRef={scheduleItemRef}
        />
      </div>
      <TimeRangeSelect
        rangeType={"all"}
        timeRange={timeRange}
        disabled={info.disabled}
        onSelectStartOption={onStartTimeSetting}
        onSelectEndOption={onStopTimeSetting}
        parentRef={scheduleItemRef}
      />
    </div>
  );
}
