import moment from "moment";
import { useEffect, useState } from "react";

export function useElapsedTimeCount() {
  const [time, setTime] = useState<string>(moment().format("A h:mm:ss"));

  useEffect(() => {
    const intervalId = setInterval(() => {
      const time = moment().format("A h:mm:ss");
      setTime(time);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return time;
}
