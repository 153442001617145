import { useForm } from "react-hook-form";
import { VALIDATE_EMAIL, VALIDATE_PASSWORD } from "../util/helper";

interface LoginType {
  username: string;
  password: string;
}

export const useLoginForm = () => {
  const f = useForm<LoginType>({
    mode: "onChange",
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const r = {
    username: f.register("username", {
      required: true,
      pattern: {
        value: VALIDATE_EMAIL,
        message: "유효하지 않은 이메일 입니다. 다시 확인해주세요.",
      },
    }),
    password: f.register("password", {
      required: true,
      pattern: {
        value: VALIDATE_PASSWORD,
        message: "8자 이상, 문자/숫자/기호 사용",
      },
    }),
  };
  return { f, r };
};
