import { Outlet, useNavigate, useLocation } from "react-router-dom";
import {
  Config,
  ConfigPlatform,
  ConfigApi,
  useController,
} from "juice-frontend-core";
import axios from "axios";
import { useEffect, useState } from "react";
import { createContext } from "react";
import { AccountController } from "../util/Controller";
import style from "../styles/layout.module.scss";
import { GlobalModal } from "../components/globalModal";
import Header from "../components/header";
import GlobalLoading from "../components/globalLoading";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const Context = createContext(-1);

export default function Layout() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const queryClient = new QueryClient();

  const [companyId, setCompanyId] = useState(-1);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  new Config(
    ConfigPlatform.Web,
    undefined,
    process.env.REACT_APP_API_URL!,
    process.env.REACT_APP_API_URL === "false"
  )
    .setWebDependency(axios, useNavigate)
    .setSpecialApi(ConfigApi.AUTH_TOKEN_REFRESH, "/api/token/refresh/")
    .setMediaQueryBoundary({
      XS: 0,
      SM: 769,
      MD: 1280,
      LG: 1600,
    });

  const ctrl = useController<AccountController>(AccountController);
  useEffect(() => {
    (async () => {
      const isLogin = await ctrl.isLogin();
      if (isLogin.ok && isLogin.res) {
        const { id } = isLogin.res;
        //여기서 global로 company id가지고 있기.
        setCompanyId(id);
        navigate("/main");
      } else {
        if (pathname === "/main") {
          navigate(`/`);
        } else {
          navigate(`${pathname}`);
        }
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ctrl, navigate]);

  return (
    <Context.Provider value={companyId}>
      <QueryClientProvider client={queryClient}>
        <div className={style.layoutContainer}>
          <Header />
          <Outlet />
          <GlobalModal />
          {isLoading && <GlobalLoading />}
        </div>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </Context.Provider>
  );
}
