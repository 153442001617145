import { SlideType } from "../pages/main";
import style from "../styles/slider.module.scss";

export default function Slider({
  children,
  title,
  isOpen,
  closeModal,
  resetSliderType,
}: {
  children: React.ReactNode;
  title: SlideType;
  isOpen: boolean;
  closeModal: () => void;
  resetSliderType: () => void;
}) {
  return (
    <>
      {isOpen && <div className={style.sliderWrapper}></div>}
      <div
        className={style.sliderContainer}
        style={{ right: isOpen ? "0px" : "-800px" }}
        onTransitionEnd={() => {
          if (!isOpen) resetSliderType();
        }}>
        <div className={style.bar}>
          <span>{title}</span>
          <div className={style.close} onClick={closeModal}>
            <img src={"/images/close.png"} alt="cancel" />
          </div>
        </div>
        <div className={style.content}>{children}</div>
      </div>
    </>
  );
}
