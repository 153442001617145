interface Props {
  fill?: string;
}

export default function ArrowDown({ fill = "white" }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="3 3 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M15.25 7.5L10.25 13.75L5.25 7.5H15.25Z" />
    </svg>
  );
}
