export class WS {
  private static instance: WS;

  retry_max?: number;
  url!: string;
  socket?: WebSocket;

  retry: number = 0;
  onFailCallback?: () => void;
  onMesageCallback!: (data: any) => void;
  constructor(
    url: string,
    onMesageCallback: (data: any) => void,
    onFailCallback: () => void,
    retry_max?: number
  ) {
    this.retry_max = retry_max;
    this.url = url;
    this.onFailCallback = onFailCallback;
    this.onMesageCallback = onMesageCallback;
  }

  public connect() {
    const sock = new WebSocket(this.url);
    this.socket = sock;
    this.socket.onopen = this.onOpen.bind(this);
    this.socket.onclose = this.onClose.bind(this);
    this.socket.onerror = this.onError.bind(this);
    this.socket.onmessage = this.onMessage.bind(this);
  }

  private reConnect() {
    this.connect();
  }
  private onOpen() {
    this.retry = 0;
    console.log("Websocket 서버 접속");
  }

  private onMessage(e: any) {
    this.onMesageCallback(JSON.parse(e.data));
    // console.log("MSG from server : ", JSON.parse(e.data).message);
  }

  private onClose(e: any) {
    if (!e.wasClean) {
      //   console.error("웹소켓 에러");
      if (this.retry < (this.retry_max ?? 3)) {
        setTimeout(() => {
          this.retry += 1;
          this.reConnect();
          console.log(`[${this.retry}] 접속 재시도 ...`);
        }, 3000 * this.retry);
      } else {
        if (this.onFailCallback) this.onFailCallback();
        //  alert("웹소켓 서버에 접속할 수 없습니다. 홈으로 이동합니다.");
        //  window.location.href = "/";
      }
    }
  }

  private onError(e: any) {
    //   this.onClose(e)
    console.log("알수없는에러");
  }

  public disConnect() {
    this.socket?.close();
  }
  public static getInstance(
    url: string,
    onMesageCallback: (data: any) => void,
    onFailCallback: () => void,
    retry_max: number
  ): WS {
    if (!WS.instance) {
      WS.instance = new WS(url, onMesageCallback, onFailCallback, retry_max);
    }

    return WS.instance;
  }
}
