/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import BigCalendar from "../components/calendar";
import { AreaController } from "../util/Controller";
import { useController } from "juice-frontend-core";
import useGlobalModal from "../util/hook/useGlobalModal";
import style from "../styles/main.module.scss";
import { Area, BgmTheme, EventAreaSchedule } from "../type/api.type";
import Player from "../components/player";
import {
  DailyAreaScheduleModel,
  EventAreaScheduleModel,
  GlobalAreaSchedulModel,
} from "../util/Model";
import ToastBar from "../components/toastbar";
import Slider from "../components/slider";
import GlobalSetting from "../components/globalSetting";
import { ManageTimeModal } from "../components/manageTimeModal";
import { TimeRange } from "../components/timeRangeSelect";
import moment from "moment";
import { Button } from "../components/button";
import { CalIcon } from "../svg/calendar";
import { useNavigate } from "react-router-dom";
import { WS } from "../util/Websocket";
import EventScheduleCreate from "../components/eventScheduleCreate";
import EventScheduleUpdate from "../components/eventScheduleUpdate";
import {
  TIME_FULL_FORMAT,
  getMoment,
  translateDayWithDate,
} from "../util/helper";
import { ToggleSwitch } from "../components/toggleSwitch";
import useLockScreen from "../util/hook/useLockScreen";
import { LockIcon } from "../svg/lock";
import { UnlockIcon } from "../svg/unlock";
import { TouchIcon } from "../svg/touch";
import { LoadingFallback } from "../components/loading";
import Barrier from "../components/barrier";
import { cloneDeep } from "lodash";
import { useQueryClient } from "@tanstack/react-query";
import { ToggleButton } from "../components/toggleButton";
import { PlayIcon } from "../svg/play";
import AllStop from "../svg/allStop";

export enum SlideType {
  AREA = "공간 관리",
  CALENDAR = "전체 일정 관리",
  SPECIAL = "일정 변경",
  NONE = "",
}

export type Theme = {
  id: number;
  title: string;
  value: string;
};

export enum ManageType {
  MONITOR = "monitor",
  PLAY = "play",
  STOP = "stop",
  VOLUME = "volume",
  MUTE = "mute",
  THEME = "theme",
  SCHEDULE = "schedule",
}

export enum EventDayType {
  CREATE,
  UPDATE,
}

export type AreaPlaybackState = {
  area: number;
  isMonitoring: boolean;
  isPlaying: boolean;
  song: string;
};

export type ManageTimeUpdateType = {
  playNow: boolean;
  needScheduled: boolean;
};

export default function Main() {
  const ctrl = useController<AreaController>(AreaController);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [selectedArea, setSelectedArea] = useState<Area>();
  const [area, setArea] = useState<Area[]>([]);
  const [monitoredArea, setMonitoredArea] = useState<number | undefined>();
  const [slidetype, setSildeType] = useState<SlideType>(SlideType.NONE);
  const [dailySchedule, setDailySchedule] = useState<DailyAreaScheduleModel[]>(
    []
  );

  const [selectedAreaSchedule, setSelectedAreaSchedule] = useState<
    GlobalAreaSchedulModel[]
  >([]);
  const [isCalendarLock, setIsCalendarLock] = useState(false);

  const {
    isScreenLockMode,
    toggleScreenLockMode,
    isIndicatingLock,
    indicateLock,
  } = useLockScreen();
  const { openModal } = useGlobalModal();
  const [themes, setThemes] = useState<Theme[]>([]);
  const [toastContent, setToastContent] = useState("");
  const [isOpenManageTimeModal, setIsOpenManageTimeModal] =
    useState<boolean>(false);
  const [timeRange, setTimeRange] = useState<TimeRange>({
    area: undefined,
    startAt: undefined,
    stopAt: undefined,
    disabled: false,
  });
  const [dailyEvents, setDailyEvents] = useState(ctrl.daily_events.value);
  const [globalSchedule, setGlobalSchedule] = useState<
    GlobalAreaSchedulModel[]
  >(ctrl.global_schedules.value);
  const [eventSchedule, setEventSchedule] =
    useState<EventAreaScheduleModel[]>();
  const [eventScheduleType, setEventScheduleType] = useState<EventDayType>(
    EventDayType.CREATE
  );
  const [eventDate, setEventDate] = useState("");

  const [prevAreaPlayStates, setPrevAreaPlayStates] = useState<
    AreaPlaybackState[]
  >([]);
  const [areaPlayStates, setAreaPlayStates] = useState<AreaPlaybackState[]>([]);
  const [calDate, setCalDate] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const getThemes = (themes: BgmTheme[]) => {
    return themes.map((theme) => ({
      id: theme.id,
      title: theme.title!,
      value: theme.title!,
    }));
  };

  const initAreaPlayStates = () => {
    const areaPlayStates: AreaPlaybackState[] = ctrl.daily_schedules.value.map(
      (v) => {
        const { area } = v;
        return {
          area: area.id,
          isMonitoring: false,
          isPlaying: false,
          song: "",
        };
      }
    );
    setPrevAreaPlayStates(areaPlayStates);
    setAreaPlayStates(areaPlayStates);
  };

  useEffect(() => {
    const profile = localStorage.getItem("profile");
    if (profile) {
      (async () => {
        setLoading(true);
        try {
          await ctrl.initData(profile);
          const res: any = await ctrl.areaStatus(
            JSON.parse(profile).location.id
          );
          if (res && res.message) handleSocketMsg(res.message);
        } catch {}
        // console.log("themes", ctrl.themes.value);
        // console.log("daily_schedules", ctrl.daily_schedules.value);
        // console.log("daily_events", ctrl.daily_events.value);
        // console.log("global_schedules", ctrl.global_schedules.value);
        setDailySchedule(ctrl.daily_schedules.value);
        setThemes(getThemes(ctrl.themes.value));
        setArea(ctrl.areas.value);
        initAreaPlayStates();
        setLoading(false);
      })();
      const location = JSON.parse(profile).location;
      const socket = WS.getInstance(
        `${process.env.REACT_APP_WS}/ws/status/update/${location.id}/`,
        (context) => handleSocketMsg(context.message),
        () => {
          console.error("WS 접속 실패");
        },
        5
      );
      socket.connect();
    } else {
      navigate("/", { replace: true });
    }
  }, []);

  const [msgStates, setMsgStates] = useState<AreaPlaybackState[]>([]);

  useEffect(() => {
    setPrevAreaPlayStates(areaPlayStates);
    const _new = cloneDeep(areaPlayStates);
    msgStates.forEach((state) => {
      const t = _new.find((i) => i.area === state.area);
      if (t) {
        const idx = _new.indexOf(t);
        state.isMonitoring = _new[idx].isMonitoring;
        _new[idx] = state;
      }
    });
    setAreaPlayStates(_new);
    setPrevAreaPlayStates(_new);
  }, [msgStates]);

  const handleSocketMsg = async (message: any) => {
    if (!message) return;
    if (typeof message === "object") {
      try {
        if (message.msg && message.msg === "play_status") {
          if (message.data) {
            const objs = JSON.parse(message.data);
            const states: AreaPlaybackState[] = [];
            // ref.current = objs;
            objs.forEach(
              (obj: {
                area: number;
                bgm: string;
                status: "playing" | "stop";
              }) => {
                const state = {
                  area: obj.area,
                  song: obj.bgm,
                  isPlaying: obj.status === "playing" ? true : false,
                  isMonitoring: false,
                } as AreaPlaybackState;
                states.push(state);
              }
            );
            console.log("states", states);

            setMsgStates(states);
          }
        } else if (message && message.msg === "status_changed") {
          setLoading(true);
          await loadDailySchedule(message.area);
        } else {
          console.log(message);
        }
      } catch {
        console.log("message : ", message);
      }
    }
  };
  const closeToastModal = () => {
    setIsToastOpen(false);
    setIsCalendarLock(false);
  };

  const onSaveToast = (context?: string) => {
    setIsToastOpen(true);
    setToastContent(context ?? "변경사항이 저장되었습니다");
  };

  const closeSliderModal = () => {
    if (isUpdated) {
      openModal({
        title: "변경된 내용을 저장하지 않았어요.",
        content: "페이지를 나가게 되면 저장하지 않은 정보를 잃게 됩니다.",
        onConfirm: () => {
          setIsSliderOpen(false);
        },
        onCancel: () => {},
      });
    } else {
      setIsSliderOpen(false);
    }
  };

  const checkValidTime = (date: string) => {
    const now = moment();
    if (moment(date) <= now) {
      onSaveToast("현재 시간보다 지난 변경 사항을 저장할 수 없어요.");
      return false;
    }
    return true;
  };
  const onCreateEventSchedule = async (events: EventAreaSchedule[]) => {
    if (!checkValidTime(events[0].date)) return;
    try {
      await ctrl.daily_events.create(events);
      onSaveToast("새로운 일정을 추가했습니다.");
    } catch (err) {
      onSaveToast("일정 추가에 실패하였습니다.");
      console.log(err);
    }
  };

  const onUpdateEventSchedule = async (events: EventAreaSchedule[]) => {
    if (!checkValidTime(events[0].date)) return;
    try {
      await ctrl.daily_events.bulk_update(events);
      onSaveToast("일정을 변경했습니다.");
    } catch (err) {
      onSaveToast("일정 변경에 실패하였습니다.");
      console.log(err);
    }
  };
  const onDeleteEventSchedule = async (events: EventAreaSchedule[]) => {
    if (!checkValidTime(events[0].date)) return;
    try {
      for (let i = 0; i < events.length; i++) {
        await ctrl.daily_events.delete(events[i].id);
      }
      onSaveToast("일정을 삭제했습니다.");
    } catch (err) {
      onSaveToast("일정 삭제에 실패하였습니다.");
      console.log(err);
    }
  };

  const onChangeMonth = async (date: Date) => {
    const cacheKey = [
      "daily_events",
      moment(date).startOf("day").format("YYYY.MM"),
    ];
    const res = await queryClient.fetchQuery({
      queryKey: cacheKey,
      queryFn: async () => {
        const response = await ctrl.daily_events.load({
          date__gte: moment(date).startOf("month").format(TIME_FULL_FORMAT),
          date__lt: moment(date).endOf("month").format(TIME_FULL_FORMAT),
          count_per_page: 217,
          page: 0,
        });
        return response;
      },
      staleTime: 60000,
    });
    const result: EventAreaScheduleModel[] = [];
    res.forEach((item) => {
      const model = new EventAreaScheduleModel();
      model.id = item.id;
      model.date = item.date;
      model.theme = item.theme[0];
      model.start_at = item.start_at;
      model.stop_at = item.stop_at;
      model.disabled = item.disabled ?? false;
      model.area = item.area;
      result.push(model);
    });
    setDailyEvents(result);
  };

  const onSelectDay = async (date: { start: Date; end: Date }, data: any) => {
    const now = moment();
    const startTime = moment(date.start);
    if (
      now.isAfter(startTime)
      // date.end <= now
    ) {
      setToastContent("오늘 이전의 일정은 추가할 수 없습니다.");
      setIsToastOpen(true);
      return;
    }
    if (data) {
      setEventScheduleType(EventDayType.UPDATE);
      setEventSchedule(data);
    } else {
      const day = translateDayWithDate(date.start);
      if (day) {
        await ctrl.global_schedules.load({
          day: day,
          "area__in[]": dailySchedule.map((i) => i.area.id),
        });
        setEventScheduleType(EventDayType.CREATE);
        setGlobalSchedule(ctrl.global_schedules.value);
      }
    }
    setEventDate(date.start.toString());
    setSildeType(SlideType.SPECIAL);
  };

  const onBackToCalendar = (date: string) => {
    setCalDate(moment(date).toDate());
    setSildeType(SlideType.CALENDAR);
  };

  const resetSliderType = () => setSildeType(SlideType.NONE);
  const getSlideComponent = (type: SlideType) => {
    switch (type) {
      case SlideType.CALENDAR:
        return (
          <BigCalendar
            onChangeMonth={onChangeMonth}
            onSelectDay={onSelectDay}
            events={dailyEvents}
            global={globalSchedule}
            calDate={calDate}
          />
        );
      case SlideType.AREA:
        return (
          <GlobalSetting
            globals={selectedAreaSchedule}
            theme_seg={themes}
            area={selectedArea!}
            onScheduleChange={onScheduleChange}
            setIsUpdated={setIsUpdated}
          />
        );
      case SlideType.SPECIAL:
        if (eventScheduleType === EventDayType.CREATE && area) {
          return (
            <EventScheduleCreate
              date={eventDate}
              globals={globalSchedule}
              area={area}
              themes={themes}
              onSave={onCreateEventSchedule}
              onBack={onBackToCalendar}
              setisUpdated={setIsUpdated}
              setLoading={setLoading}
              isUdated={isUpdated}
            />
          );
        } else if (eventSchedule && area) {
          return (
            <EventScheduleUpdate
              date={eventDate}
              events={eventSchedule}
              area={area}
              themes={themes}
              onSave={onUpdateEventSchedule}
              onDelete={onDeleteEventSchedule}
              onBack={onBackToCalendar}
              setisUpdated={setIsUpdated}
              setLoading={setLoading}
              isUdated={isUpdated}
            />
          );
        } else {
          return <></>;
        }
      default:
        return <></>;
    }
  };

  const onScheduleChange = async (area: number, body: any) => {
    setIsSliderOpen(false);
    setIsUpdated(false);
    await ctrl.global_schedules.bulk_update(area, body);
    onSaveToast();
  };

  const openManageTimeModal = (
    startAt: string,
    stopAt: string,
    area: number,
    disabled: boolean
  ) => {
    setIsOpenManageTimeModal(true);
    if (!isOpenManageTimeModal) {
      const [startHH, startMM] = startAt.split(":");
      const [stopHH, stopMM] = stopAt.split(":");
      setTimeRange({
        area: area,
        startAt: getMoment(startHH, startMM, 0),
        stopAt: getMoment(stopHH, stopMM, 0),
        disabled,
      });
    }
  };

  const closeManageTimeModal = () => {
    setIsOpenManageTimeModal(false);
    setTimeRange({
      area: undefined,
      startAt: undefined,
      stopAt: undefined,
      disabled: false,
    });
  };

  const checkIsAnyBgmPlaying = (playingStates: AreaPlaybackState[]) =>
    playingStates.some((area) => area.isPlaying === true);

  const handleOnPlay = async (
    schedule: DailyAreaScheduleModel,
    isPlaying: boolean
  ) => {
    setLoading(true);
    const areaId = schedule.area.id;
    // const playingStates = getPlayingStates(areaId, isPlaying);
    // setPrevAreaPlayStates(areaPlayStates);
    // setAreaPlayStates(playingStates);
    try {
      await ctrl.manageDailySchedule(
        areaId,
        isPlaying ? ManageType.PLAY : ManageType.STOP,
        undefined
      );
      setLoading(false);
    } catch {
      // setAreaPlayStates(prevAreaPlayStates);
    }
  };

  const handleOnPlayAll = async () => {
    const isAnyBgmPlaying = checkIsAnyBgmPlaying(areaPlayStates);
    setLoading(true);
    try {
      await ctrl.manageDailySchedule(
        isAnyBgmPlaying
          ? areaPlayStates
              .filter((area) => area.isPlaying)
              .map((area) => area.area)
          : dailySchedule
              .filter((daily) => !daily.disabled)
              .map((daily) => daily.area.id),
        isAnyBgmPlaying ? ManageType.STOP : ManageType.PLAY,
        undefined
      );
      setLoading(false);
    } catch {
      setAreaPlayStates(prevAreaPlayStates);
    }
  };

  const getPlayingStates = (areaId: number, isPlaying: boolean) => {
    return areaPlayStates.map((area) => {
      const isPlayingSelectedArea =
        areaId === area.area ? isPlaying : area.isPlaying;
      return {
        ...area,
        isPlaying: isPlayingSelectedArea,
      };
    });
  };

  const getIsPlaying = (schedule: DailyAreaScheduleModel) => {
    const area = areaPlayStates.find((area) => area.area === schedule.area.id);
    return area ? area.isPlaying : false;
  };

  // 개별 플레이어 모니터링 버튼 클릭 시
  const handleOnMonitor = async (schedule: DailyAreaScheduleModel) => {
    const areaId = schedule.area.id;
    setMonitoredArea(areaId === monitoredArea ? undefined : areaId);
    const monitoringStates = getMonitoringStates(areaId);
    setPrevAreaPlayStates(areaPlayStates);
    setAreaPlayStates(monitoringStates);
    try {
      await ctrl.manageDailySchedule(areaId, ManageType.MONITOR, undefined);
    } catch {
      setAreaPlayStates(prevAreaPlayStates);
    }
  };

  const getMonitoringStates = (areaId: number) => {
    return areaPlayStates.map((area) => {
      const isMonitoring = area.area === areaId && !area.isMonitoring;
      return {
        ...area,
        isMonitoring,
      };
    });
  };

  const loadDailySchedule = async (area_ids?: number[]) => {
    await ctrl.daily_schedules.load({
      "area__in[]": area_ids ?? area!.map((i) => i.id),
      date__lt: moment().add(1, "day").format("YYYY-MM-DD"),
      date__gte: moment().format("YYYY-MM-DD"),
      // count_per_page: area_ids ? 100 : area!.length,
      // page: 1,
    });

    setDailySchedule(ctrl.daily_schedules.value);
    setLoading(false);
  };

  const handleOnManageTheme = async (
    schedule_id: number,
    area_id: number,
    theme: Theme,
    updateType: ManageTimeUpdateType
  ) => {
    try {
      setLoading(true);
      await ctrl.manageDailySchedule(
        area_id,
        ManageType.THEME,
        {
          scheduleType: updateType,
          theme: [theme.id],
        },
        async () => {
          await ctrl.daily_schedules.update(schedule_id, {
            theme: [theme.id],
          });
          await loadDailySchedule();
        }
      );
      onSaveToast(
        ` '${
          ctrl.daily_schedules.value.find((i) => i.id === schedule_id)?.area
            .title
        }' 공간의 테마가 변경되었습니다.`
      );
    } catch {}
  };

  const handleOnManageTime = async (
    attr: TimeRange,
    updateType: ManageTimeUpdateType
  ) => {
    if (!attr.area) return;
    if (
      attr.disabled &&
      dailySchedule.find((i) => i.area.id === attr.area)?.disabled
    ) {
      console.log("already disabled");
      return;
    }
    setLoading(true);
    await ctrl.manageDailySchedule(
      attr.area,
      ManageType.SCHEDULE,
      {
        start: moment(attr.startAt).format(TIME_FULL_FORMAT),
        stop: moment(attr.stopAt).format(TIME_FULL_FORMAT),
        disabled: attr.disabled,
        scheduleType: updateType,
      },
      async () => {
        await ctrl.daily_schedules.update(
          dailySchedule.find((s) => s.area.id === attr.area)!.id,
          {
            start_at: moment(attr.startAt).format(TIME_FULL_FORMAT),
            stop_at: moment(attr.stopAt).format(TIME_FULL_FORMAT),
            disabled: attr.disabled,
          }
        );
        await loadDailySchedule();
      }
    );

    onSaveToast(
      ` '${
        area!.find((a) => a.id === attr.area)?.title
      }' 공간의 시간이 변경되었습니다.`
    );
  };

  const handleOnToggleLockMode = (isLock: boolean) => {
    toggleScreenLockMode(!isLock);
    indicateLock(!isLock);
  };

  const handleGlobalSetting = async (schedule: DailyAreaScheduleModel) => {
    await ctrl.global_schedules.load({
      area: schedule.area.id,
      order_by: "id",
    });
    setSelectedArea(schedule.area);
    setSelectedAreaSchedule(ctrl.global_schedules.value);
    setSildeType(SlideType.AREA);
    setIsSliderOpen(true);
  };

  //  useEffect(() => {
  //    if (dailySchedule.find((i) => !i.muted)) {
  //      setIsAllMute(false);
  //    } else {
  //      if (dailySchedule.filter((i) => i.muted).length === dailySchedule.length)
  //        setIsAllMute(true);
  //    }
  //  }, [dailySchedule]);

  //  const [isAllMute, setIsAllMute] = useState(false);
  // const handleAllMute = async (muted: boolean) => {
  //   setLoading(true);
  //   await ctrl.manageDailySchedule(
  //     area.map((i) => i.id),
  //     ManageType.MUTE,
  //     muted,
  //     async () => {
  //       await ctrl.daily_schedules.bulk_update(
  //         dailySchedule.map((d) => {
  //           return { id: d.id, muted: muted };
  //         })
  //       );
  //       await loadDailySchedule();
  //     }
  //   );
  // };
  //  const handleOnManageVolume = async (
  //    scheduel_id: number,
  //    area_id: number,
  //    volume: number
  //  ) => {
  //    setLoading(true);
  //    await ctrl.manageDailySchedule(
  //      area_id,
  //      ManageType.VOLUME,
  //      volume,
  //      async () => {
  //        await ctrl.daily_schedules.update(scheduel_id, {
  //          volume: volume,
  //        });
  //        await loadDailySchedule();
  //      }
  //    );
  //  };

  return (
    <>
      <LoadingFallback isOpen={loading} />
      {dailySchedule.length > 0 && (
        <div className={style.mainContainer}>
          <div className={style.mainWrapper}>
            <div className={style.control}>
              <div
                className={style.playbackControl}
                style={{
                  pointerEvents: isScreenLockMode ? "none" : "auto",
                }}
              >
                <ToggleButton
                  active={checkIsAnyBgmPlaying(areaPlayStates)}
                  onClick={handleOnPlayAll}
                  icon={
                    checkIsAnyBgmPlaying(areaPlayStates)
                      ? AllStop
                      : () => <PlayIcon />
                  }
                  defaultStyle={{
                    backgroundColor: "#424242",
                  }}
                  activeStyle={{
                    backgroundColor: "#0096FF",
                  }}
                />
                <Button
                  W={101}
                  H={38}
                  confirmText={"일정관리"}
                  onClick={() => {
                    setSildeType(SlideType.CALENDAR);
                    setIsSliderOpen(true);
                  }}
                  icon={CalIcon}
                  active={!(isCalendarLock || isScreenLockMode)}
                />
              </div>
              <div className={style.lockControl}>
                <div className={style.noticeTitle}>
                  <div className={style.icon}>
                    <TouchIcon />
                  </div>
                  <div className={style.text}>
                    컨트롤러 조작 시, 인터넷 환경에 따라 약간의 지연이 발생할 수
                    있습니다.
                  </div>
                </div>
                <div
                  className={style.lockForm}
                  style={{
                    border: isIndicatingLock
                      ? "1px solid #0096FF"
                      : "1px solid transparent",
                  }}
                >
                  <div className={style.lockTitle}>
                    <div className={style.icon}>
                      {isScreenLockMode ? <LockIcon /> : <UnlockIcon />}
                    </div>
                    <div className={style.text}>잠금모드</div>
                  </div>
                  <ToggleSwitch
                    isChecked={isScreenLockMode}
                    onToggle={handleOnToggleLockMode}
                  />
                </div>
              </div>
            </div>
            <div
              className={style.playerList}
              style={{
                pointerEvents: isScreenLockMode ? "none" : "auto",
              }}
            >
              {dailySchedule
                .sort((a, b) => a.area.order - b.area.order)
                .map((schedule, i) => (
                  <Player
                    key={`player: ${i}`}
                    sliderId={`player: ${i}`}
                    info={schedule}
                    themes={themes}
                    currInfo={areaPlayStates.find(
                      (a) => a.area === schedule.area.id
                    )}
                    onManage={() => handleGlobalSetting(schedule)}
                    isPlaying={getIsPlaying(schedule)}
                    isMonitoring={false}
                    onMonitor={() => handleOnMonitor(schedule)}
                    onManageTime={openManageTimeModal}
                    onPlay={(isPlaying) => handleOnPlay(schedule, isPlaying)}
                    onManageTheme={(theme, updateType) =>
                      handleOnManageTheme(
                        schedule.id,
                        schedule.area.id,
                        theme,
                        updateType
                      )
                    }
                    disabled={schedule.disabled}
                    isLock={isScreenLockMode}
                    // isMonitoring={monitoredArea === schedule.area.id}
                    // onVolume={(vol) =>
                    //   handleOnManageVolume(schedule.id, schedule.area.id, vol)
                    // }
                    // isAllMute={isAllMute}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
      {isOpenManageTimeModal && (
        <ManageTimeModal
          timeRange={timeRange}
          closeModal={closeManageTimeModal}
          onConfirm={handleOnManageTime}
        />
      )}
      <Slider
        closeModal={closeSliderModal}
        isOpen={isSliderOpen}
        resetSliderType={resetSliderType}
        title={slidetype}
      >
        {getSlideComponent(slidetype)}
      </Slider>
      <ToastBar
        isOpen={isToastOpen}
        closeModal={closeToastModal}
        content={toastContent}
      />
      <Barrier />
    </>
  );
}

export const checkUpdateType = (
  startAt: moment.Moment,
  stopAt: moment.Moment
): ManageTimeUpdateType => {
  const m = moment();
  const playNow = m.isBetween(startAt, stopAt);
  const needScheduled = m.isBefore(startAt);
  return {
    playNow,
    needScheduled,
  };
};
