/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { Area, EventAreaSchedule } from "../type/api.type";
import { EventAreaScheduleModel } from "../util/Model";
import style from "../styles/eventSchedule.module.scss";
import { Button } from "./button";
import { ToggleButton } from "./toggleButton";
import Return from "../svg/return";
import { ToggleSwitch } from "./toggleSwitch";
import DropDownMenu from "./dropDownMenu";
import { FilterIcon } from "../svg/filter";
import { TimeRange, TimeRangeSelect } from "./timeRangeSelect";
import { useEffect, useRef, useState } from "react";
import { Theme } from "../pages/main";
import { cloneDeep, isEqual } from "lodash";
import Discard from "../svg/discard";
import CalIcon2 from "../svg/calendar2";
import { TIME_FULL_FORMAT } from "../util/helper";
import { useQueryClient } from "@tanstack/react-query";
import useGlobalModal from "../util/hook/useGlobalModal";

export default function EventScheduleUpdate({
  date,
  events,
  area,
  themes,
  onSave,
  onDelete,
  onBack,
  setisUpdated,
  isUdated,
  setLoading,
}: {
  date: string;
  events: EventAreaScheduleModel[];
  area: Area[];
  themes: Theme[];
  onSave: (events: EventAreaSchedule[], date: string) => Promise<void>;
  onDelete: (events: EventAreaSchedule[], date: string) => Promise<void>;
  onBack: (date: string) => void;
  setisUpdated: (value: boolean) => void;
  isUdated: boolean;
  setLoading: (value: boolean) => void;
}) {
  const { openModal } = useGlobalModal();

  const [org, setOrg] = useState<EventAreaScheduleModel[]>([]);
  const [updatedSchedule, setUpdatedSchedule] = useState<
    EventAreaScheduleModel[]
  >([]);

  const queryClient = useQueryClient();

  const onDeleteSchedule = () => {
    openModal({
      title: "이벤트를 삭제하시겠습니까?",
      content:
        "삭제를 누르면 이벤트가 삭제되고,\n해당 날짜의 시간이 기본 운영 시간으로 초기화 됩니다.",
      onConfirm: async () => {
        setLoading(true);
        setisUpdated(false);
        const schedule: EventAreaSchedule[] = [];
        updatedSchedule.forEach((s) => {
          const _new = {} as EventAreaSchedule;
          _new.id = s.id;
          _new.area = s.area;
          _new.date = s.date;
          _new.disabled = s.disabled;
          _new.start_at = s.start_at;
          _new.stop_at = s.stop_at;
          _new.theme = [s.theme];
          schedule.push(_new);
        });
        await onDelete(schedule, date);
        resetQuery();
        setLoading(false);
        onBack(date);
      },
      confirmText: "삭제",
      onCancel: () => {},
    });
  };

  const onBackToCalendar = () => {
    if (isUdated) {
      openModal({
        title: "변경된 내용을 저장하지 않았어요.",
        content: "페이지를 나가게 되면 저장하지 않은 정보를 잃게 됩니다.",
        onConfirm: () => {
          onBack(date);
          setisUpdated(false);
        },
        onCancel: () => {},
      });
    } else {
      onBack(date);
    }
  };

  const resetQuery = () => {
    queryClient.invalidateQueries({
      queryKey: ["daily_events", moment(date).format("YYYY.MM")],
    });
  };

  const onSaveUpdate = async () => {
    setLoading(true);
    setisUpdated(false);
    const schedule: EventAreaSchedule[] = [];
    updatedSchedule.forEach((s) => {
      const _new = {} as EventAreaSchedule;
      _new.id = s.id;
      _new.area = s.area;
      _new.date = s.date;
      _new.disabled = s.disabled;
      _new.start_at = s.start_at;
      _new.stop_at = s.stop_at;
      _new.theme = [s.theme];
      schedule.push(_new);
    });
    await onSave(schedule, date);
    resetQuery();
    setLoading(false);
    onBack(date);
  };

  const onSetting = (data: EventAreaScheduleModel, i: number) => {
    const _new = cloneDeep(updatedSchedule);
    _new.splice(i, 1);
    _new.splice(i, 0, data);
    setUpdatedSchedule([..._new]);
  };

  useEffect(() => {
    const todayEvent = events
      .sort(
        (a, b) =>
          area.find((k) => k.id === a.area)!.order -
          area.find((k) => k.id === b.area)!.order
      )
      .filter((event) => moment(event.date).isSame(moment(date)));

    setOrg(cloneDeep(todayEvent));
    setUpdatedSchedule(cloneDeep(todayEvent));
  }, []);

  useEffect(() => {
    if (!isEqual(updatedSchedule, org)) setisUpdated(true);
    else setisUpdated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedSchedule]);

  return (
    <div className={style.specialSetting}>
      <div className={style.content}>
        <div className={style.setting}>
          <div className={style.calendar}>
            <CalIcon2 />
            <strong>
              {moment(date).format("YYYY[년] MM[월] DD[일] (dd)")}
            </strong>
          </div>
          <div className={style.control}>
            <ToggleButton
              text="이벤트 삭제하기"
              activeStyle={{
                border: "1px solid #D7506B",
                backgroundColor: "#343434",
                color: "#D7506B",
              }}
              onClick={onDeleteSchedule}
              icon={() => <Discard />}
              active={true}
              W={134}
              H={32}
            />
            <ToggleButton
              text="설정 복귀하기"
              activeStyle={{
                border: "1px solid #0096FF",
                backgroundColor: "#343434",
                color: "#0096FF",
              }}
              defaultStyle={{
                border: "1px solid #757575",
                backgroundColor: "#343434",
                color: "#757575",
              }}
              onClick={() => setUpdatedSchedule(cloneDeep(org))}
              icon={() => (
                <Return
                  fill={!isEqual(org, updatedSchedule) ? "#0096FF" : "#757575"}
                />
              )}
              active={!isEqual(org, updatedSchedule)}
              W={122}
              H={32}
            />
          </div>
        </div>
        <div className={style.schedules}>
          {updatedSchedule.map((e, i) => (
            <ItemSetting
              key={`special ${i}`}
              info={e}
              themes={themes}
              area={area}
              onSetting={(data) => onSetting(data, i)}
            />
          ))}
        </div>
      </div>
      <div className={style.bottom}>
        <ToggleButton
          text="전체일정으로 돌아가기"
          activeStyle={{
            textDecoration: "underline",
            backgroundColor: "#343434",
            color: "#0096FF",
          }}
          defaultStyle={{
            border: "1px solid #757575",
            backgroundColor: "#343434",
            color: "#757575",
          }}
          onClick={onBackToCalendar}
          active={true}
          W={140}
          H={35}
        />
        <Button
          active={isUdated}
          type={"confirm"}
          onClick={onSaveUpdate}
          confirmText={"저장"}
        />
      </div>
    </div>
  );
}

function ItemSetting({
  info,
  themes,
  area,
  onSetting,
}: {
  info: EventAreaScheduleModel;
  themes: Theme[];
  area: Area[];
  onSetting: (event: EventAreaScheduleModel) => void;
}) {
  const scheduleItemRef = useRef<HTMLDivElement | null>(null);

  const [timeRange, setTimeRange] = useState<TimeRange>({
    area: undefined,
    startAt: undefined,
    stopAt: undefined,
    disabled: false,
  });

  const onToggleSwitch = (e: boolean) => {
    info.disabled = e;
    onSetting(info);
  };

  const onDropDown = (e: { id: number; title: string; value: string }) => {
    info.theme = e.id;
    onSetting(info);
  };

  const onSelectStartTime = (
    startAt?: moment.Moment,
    stopAt?: moment.Moment
  ) => {
    info.start_at = startAt!.format(TIME_FULL_FORMAT);
    info.stop_at = stopAt!.format(TIME_FULL_FORMAT);
    onSetting(info);
  };

  const onSelectEndTime = (option?: moment.Moment) => {
    if (option) {
      info.stop_at = option.format(TIME_FULL_FORMAT);
      onSetting(info);
    }
  };

  useEffect(() => {
    setTimeRange({
      startAt: moment(info.start_at),
      stopAt: moment(info.stop_at),
      disabled: false,
    });
  }, [info]);

  return (
    <div
      className={style.schedule}
      ref={scheduleItemRef}
    >
      <div className={style.item1}>
        <ToggleSwitch
          isChecked={!info.disabled}
          onToggle={onToggleSwitch}
        />
        <div>{area.find((a) => a.id === info.area)?.title}</div>
        <DropDownMenu
          currOption={themes.find((t) => t.id === info.theme) as any}
          options={themes}
          onSelectOption={onDropDown}
          disabled={info.disabled}
          icon={() => <FilterIcon fill={info.disabled ? "#616161" : "white"} />}
          parentRef={scheduleItemRef}
        />
      </div>
      <TimeRangeSelect
        rangeType={"all"}
        timeRange={timeRange}
        onSelectStartOption={onSelectStartTime}
        onSelectEndOption={onSelectEndTime}
        disabled={info.disabled}
        parentRef={scheduleItemRef}
      />
    </div>
  );
}
