import { useEffect, useRef, useState } from "react";
import { useController } from "juice-frontend-core";
import { ScreenLockController } from "../Controller";

export default function useLockScreen() {
  const ctrl = useController<ScreenLockController>(ScreenLockController);
  const [isScreenLockMode, setIsScreenLockMode] = useState<boolean>(false);
  const [isIndicatingLock, setIsIndicatingLock] = useState<boolean>(false);
  const indicatingLockTimer = useRef<NodeJS.Timer | null>(null);

  const toggleScreenLockMode = (isScreenLock: boolean) => {
    ctrl.toggleScreenLockMode(isScreenLock);
  };

  const indicateLock = (visible: boolean) => {
    if (indicatingLockTimer.current) {
      clearTimeout(indicatingLockTimer.current);
      ctrl.indicateScreenLockMode(false);
    }
    ctrl.indicateScreenLockMode(visible);
    if (visible) {
      indicatingLockTimer.current = setTimeout(() => {
        indicatingLockTimer.current = null;
        ctrl.indicateScreenLockMode(false);
      }, 3000);
    }
  };

  useEffect(() => {
    const sub = ctrl.screenLockMode.subscribe((isScreenLock) => {
      setIsScreenLockMode(isScreenLock);
    });
    const prevLock = localStorage.getItem("lock");
    if (prevLock) setIsScreenLockMode(JSON.parse(prevLock));

    return () => {
      sub.unsubscribe();
    };
  }, [ctrl.screenLockMode]);

  useEffect(() => {
    const sub = ctrl.indicatingLock.subscribe((isIndicating) => {
      setIsIndicatingLock(isIndicating);
    });
    const prevLock = localStorage.getItem("lock");
    if (prevLock) setIsIndicatingLock(JSON.parse(prevLock));

    return () => {
      sub.unsubscribe();
    };
  }, [ctrl.indicatingLock]);

  return {
    isScreenLockMode,
    toggleScreenLockMode,
    isIndicatingLock,
    indicateLock,
  };
}
