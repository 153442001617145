import { useForm } from "react-hook-form";
import { VALIDATE_EMAIL, VALIDATE_PASSWORD } from "../util/helper";

export interface SignupType {
  email: string;
  password: string;
  check: string;
  email_code: string;
}

export const useRegisterForm = () => {
  const passwordConditionPlaceholder = "8자 이상, 문자/숫자/기호 사용";
  const passwordNotMatched = "비밀번호와 일치하지 않습니다.";

  const f = useForm<SignupType>({
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
      check: "",
      email_code: "",
    },
  });

  const r = {
    email: f.register("email", {
      required: true,
      pattern: {
        value: VALIDATE_EMAIL,
        message: "유효하지 않은 이메일 입니다. 다시 확인해주세요.",
      },
    }),
    email_code: f.register("email_code", {
      required: true,
      maxLength: 10,
      minLength: 6,
    }),
    password: f.register("password", {
      required: true,
      onChange: () => {
        const value = f.getValues("password");
        if (value.length === 0) f.resetField("check"); // on reset strong password in ios safari
        if (value.length > 20) {
          f.setValue("password", value.slice(0, 20));
        }
        if (f.getValues("check").length === 0) {
          return;
        } else {
          if (f.getValues("password") === f.getValues("check")) {
            f.clearErrors("check");
          } else {
            f.setError("check", {
              message: "비밀번호와 일치하지 않습니다.",
            });
          }
        }
      },
      validate: {
        include: (value) => {
          const reg = VALIDATE_PASSWORD;
          if (reg.test(value)) {
            return true;
          } else {
            return passwordConditionPlaceholder;
          }
        },
      },
    }),
    check: f.register("check", {
      required: "비밀번호를 입력하세요",
      validate: {
        isMatch: (value) => {
          if (value === f.getValues("password")) {
            return true;
          } else {
            return passwordNotMatched;
          }
        },
      },
      onChange() {
        const value = f.getValues("check");
        if (value.length > 20) {
          f.setValue("check", value.slice(0, 20));
        }
      },
    }),
  };

  return { f, r };
};
