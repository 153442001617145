export default function Success({ fill = "#C2C2C2" }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 12L10.75 15.75L17 9.5"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2.5"
        y="2.5"
        width="19"
        height="19"
        rx="9.5"
        stroke={fill}
      />
    </svg>
  );
}
