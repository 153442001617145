import style from "../styles/loading.module.scss";
import loading from "../image/loading.gif";

export function LoadingFallback({ isOpen }: { isOpen: boolean }) {
  return (
    <>
      {isOpen ? (
        <div className={style.loading}>
          <div className={style.msgContainer}>
            <img
              className={style.icon}
              src={loading}
              style={{
                shapeRendering: "auto",
              }}
              alt={"loading"}
            ></img>
            <div className={style.text}>로딩중입니다.</div>
          </div>
          <div className={style.fallbackContainer}>
            <div className={style.controlContainer}>
              <div className={style.btnContainer}>
                {[...Array(1)].map((_, idx) => {
                  return (
                    <div
                      key={idx}
                      className={style.btn}
                    ></div>
                  );
                })}
              </div>
            </div>
            <div className={style.itemContainer}>
              {[...Array(7)].map((_, idx) => {
                return (
                  <div
                    key={idx}
                    className={style.loadingItem}
                  ></div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
