export function WarningIcon() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="30"
        cy="30"
        r="25.5"
        fill="#757575"
      />
      <path
        d="M32.707 17.5469L32.25 35.3711H27.75L27.293 17.5469H32.707ZM27.082 40.3633C27.0469 38.7461 28.3828 37.4277 30.0352 37.4453C31.5996 37.4277 32.9531 38.7461 32.9531 40.3633C32.9531 41.998 31.5996 43.3164 30.0352 43.3164C28.3828 43.3164 27.0469 41.998 27.082 40.3633Z"
        fill="white"
      />
    </svg>
  );
}
