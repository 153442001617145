import style from "../styles/button.module.scss";

interface Props {
  type?: "cancel" | "confirm";
  W?: number | string;
  H?: number | string;
  color?: string;
  background?: string;
  active?: boolean;
  confirmText?: string;
  cancelText?: string;
  onClick: () => void;
  icon?: () => JSX.Element;
}

export function Button({
  type,
  W,
  H,
  color,
  background,
  confirmText,
  cancelText,
  active = true,
  onClick,
  icon,
}: Props) {
  const title =
    type === "cancel" ? cancelText ?? "취소" : confirmText ?? "확인";

  const backgroundColor =
    background ??
    (type === "cancel"
      ? "#EDEDED"
      : type === "confirm"
      ? active
        ? "#0096FF"
        : "#424242"
      : "#424242");

  const textColor =
    color ??
    (type === "cancel"
      ? "#9E9E9E"
      : type === "confirm"
      ? "#FFFFFF"
      : "#FFFFFF");

  return (
    <div
      className={style.button}
      onClick={onClick}
      style={{
        pointerEvents: active ? "auto" : "none",
        width: W ?? 150,
        height: H ?? 48,
        backgroundColor,
      }}
    >
      <div className={style.titleContainer}>
        {icon ? <div className={style.icon}>{icon()}</div> : <></>}
        {title === "" ? (
          <></>
        ) : (
          <div
            className={style.title}
            style={{
              fontSize: type ? 16 : 14,
              color: textColor,
            }}
          >
            {title}
          </div>
        )}
      </div>
    </div>
  );
}
