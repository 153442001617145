import Logo from "../svg/logo";
import style from "../styles/globalLoading.module.scss";

export default function GlobalLoading() {
  return (
    <div className={style.globalLoading}>
      <Logo />
    </div>
  );
}
