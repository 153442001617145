export function StopIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="4"
        y="4"
        width="12"
        height="12"
        rx="1"
        fill="white"
      />
    </svg>
  );
}
