import { Api, Controller, Manager, PureManager } from "juice-frontend-core";
import {
  AuthUser,
  Area,
  Location,
  Account,
  DailyAreaSchedule,
  GlobalAreaSchedule,
  BgmTheme,
  EventAreaSchedule,
} from "../type/api.type";
import {
  DailyAreaScheduleModel,
  EventAreaScheduleModel,
  GlobalAreaSchedulModel,
} from "./Model";
import moment from "moment";
import { ManageType } from "../pages/main";
import { BehaviorSubject } from "rxjs";
import { GlobalModalProps } from "../components/globalModal";

export class AccountController extends Controller {
  constructor() {
    super("AccountController");
  }

  public isLoading = new BehaviorSubject<boolean>(true);

  async login(username: string, password: string) {
    const user = await Api.list<any[]>("/api-user/", {
      username,
    });

    if (!user[0] || !user[0].name) return Promise.reject("no user");
    if (!user[0].active) return Promise.reject("not active");
    const jwt = await Api.create<{ access: string; refresh: string }>(
      "/api/token/",
      {
        username,
        password,
      }
    );

    if (!jwt) return Promise.reject("wrong pw");

    if (user[0].id) {
      const location = await this.checkValidArea(user[0].id);
      await Api.signIn({ ...user[0], location }, jwt.access, jwt.refresh);

      return { user: user[0].id! };
    }
  }

  async checkValidArea(user_id: number) {
    try {
      const account = await Api.list<Account[]>(Account.API, {
        user: user_id,
      });

      if (!account[0]) return Promise.reject("no account");

      if (account[0].company[0]) {
        const company_id = account[0].company[0];
        //첫번쨰 건물 호출
        const location: Location[] = await Api.list(Location.API, {
          company: company_id,
        });
        return location[0];
      }
    } catch {
      return Promise.reject("no active account or area");
    }
  }

  async isLogin() {
    const res = await Api.list<{ id: number; user: number }>("/api-user/", {
      user_self: true,
    });
    if (res?.id)
      return {
        ok: true,
        res,
        msg: "이상 무",
      };
    else
      return {
        ok: false,
        res: undefined,
        msg: "유효하지 않음",
      };
  }
  async sendMail(email: string, context: string, onAlreadyExists?: () => void) {
    const user = await Api.list<{ name?: string; id?: string }[]>(
      AuthUser.API,
      {
        username: email,
      }
    );
    if (!user[0]) {
      onAlreadyExists && onAlreadyExists();
      return Promise.reject("not registered");
    }

    const res = await Api.list<{ result: string; code: string }>(
      "/api/send_mail/",
      {
        email,
        title: `[Genie Bgm]  인증코드 발송`,
        context: context,
      }
    );
    return res.code;
  }

  async register(email: string, password: string) {
    const user = await Api.list<
      { name?: string; id?: number; active?: boolean }[]
    >(AuthUser.API, {
      username: email,
    });
    if (!user[0] || !user[0].id) {
      return false;
    }
    if (user[0].active) {
      window.alert("이미 활성화된 계정입니다.");
      return false;
    }
    await Api.update(AuthUser.API, user[0].id, {
      password: password,
      is_active: true,
    });

    return true;
  }

  async sendValidationMail(
    email: string,
    context: string,
    onNoExists?: () => void
  ) {
    const user = await Api.list<
      { name?: string; id?: string; active?: boolean }[]
    >(AuthUser.API, {
      username: email,
    });
    if (!user[0]) {
      onNoExists && onNoExists();
      return Promise.reject("no mail");
    }
    if (!user[0].active) {
      window.alert("회원가입부터 진행해 주세요.");
      return false;
    }

    const res = await Api.list<{ result: string; code: string }>(
      "/api/send_mail/",
      {
        email,
        title: `[Genie Bgm]  인증코드 발송`,
        context: context,
      }
    );
    return res.code;
  }

  async changePassword(email: string, new_password: string) {
    const user = await Api.list<{ email: string; id: number }[]>(AuthUser.API, {
      username: email,
    });

    if (user[0]) {
      const res = await Api.update<{ result: string }>(
        AuthUser.API,
        user[0].id,
        {
          password: new_password,
        }
      );
      if (res) {
        return true;
      } else {
        Promise.reject("internal server error");
      }
      return true;
    } else {
      return false;
    }
  }
}
class DailyScheduleManager extends Manager<
  DailyAreaSchedule,
  DailyAreaScheduleModel
> {
  API = DailyAreaSchedule.API;
  BULK_API = DailyAreaSchedule.BULK_UPDATE_API;
  async expand(item: DailyAreaSchedule[]) {
    const schedules: DailyAreaScheduleModel[] = [];
    const dailySchedule = await Api.expand<DailyAreaSchedule<Area>>(
      item,
      "area",
      Area.API
    );
    const dailyWithTheme = await Api.expand<DailyAreaSchedule<Area, BgmTheme>>(
      dailySchedule,
      "theme",
      BgmTheme.API,
      true
    );
    for (let i = 0; i < dailyWithTheme.length; i++) {
      const daily = dailyWithTheme[i];
      const model = new DailyAreaScheduleModel();
      model.id = daily.id;
      model.area = {
        id: daily.area.id,
        title: daily.area.title,
        channel: daily.area.audio_channel ?? "",
        device: daily.area.device,
        order: daily.area.order,
        location: daily.area.location,
      };
      model.date = moment(daily.date);
      model.theme = String(daily.theme[0].title);
      model.is_day = daily.is_day;
      model.start_at = moment(daily.start_at).format("HH:mm");
      model.stop_at = moment(daily.stop_at).format("HH:mm");
      model.volume = daily.volume;
      model.disabled = daily.disabled ?? false;
      model.muted = daily.muted;
      schedules.push(model);
    }

    return schedules;
  }
  async bulk_update(query: any) {
    await Api.create(this.BULK_API, query);
  }
}

export class GlobalScheduleManager extends Manager<
  GlobalAreaSchedule,
  GlobalAreaSchedulModel
> {
  API = GlobalAreaSchedule.API;
  BULK_API = GlobalAreaSchedule.BULK_UPDATE_API;
  async expand(items: GlobalAreaSchedule[]) {
    const result = await Api.expand<GlobalAreaSchedulModel>(
      items,
      "theme",
      BgmTheme.API,
      true
    );
    return result;
  }

  async bulk_update(area: number, query: any) {
    // console.log(query);
    await Api.create(this.BULK_API, query);
  }
}

export class EventAreaScheduleManager extends Manager<
  EventAreaSchedule,
  EventAreaScheduleModel
> {
  API = EventAreaSchedule.API;
  BULK_API = EventAreaSchedule.BULK_UPDATE_API;
  async expand(items: EventAreaSchedule[]) {
    // const eventWithArea = await Api.expand<EventAreaScheduleModel>(
    //   items,
    //   "area",
    //   Area.API
    // );
    const result: EventAreaScheduleModel[] = [];

    items.forEach((item) => {
      const model = new EventAreaScheduleModel();
      model.id = item.id;
      model.date = item.date;
      model.theme = item.theme[0];
      model.start_at = item.start_at;
      model.stop_at = item.stop_at;
      model.disabled = item.disabled ?? false;
      model.area = item.area;
      result.push(model);
    });

    return result;
  }

  async bulk_update(query: any) {
    // console.log(query);
    await Api.create(this.BULK_API, query);
  }
}

export class ScheduleController extends Controller {
  global_schedules = new GlobalScheduleManager();
  daily_events = new EventAreaScheduleManager();
}
export class AreaController extends Controller {
  areas = new PureManager<Area>(Area);
  // areas = new AreaManager();
  themes = new PureManager<BgmTheme>(BgmTheme);
  daily_schedules = new DailyScheduleManager();
  global_schedules = new GlobalScheduleManager();
  daily_events = new EventAreaScheduleManager();

  async initData(raw_info: string) {
    const info = JSON.parse(raw_info);
    if (!info.location || !info.location.id)
      return Promise.reject("no location info");
    await this.themes.load({});
    await this.areas.load({
      location: info.location.id,
      order_by: "order",
    });
    await this.daily_schedules.load({
      "area__in[]": this.areas.value.map((i) => i.id),
      date__lt: moment().add(1, "day").format("YYYY-MM-DD"),
      date__gte: moment().format("YYYY-MM-DD"),
      count_per_page: this.areas.value.length,
      page: 1,
    });
  }

  async areaStatus(location: number) {
    return await Api.list("/api-bgm/status/", {
      location: location,
    });
  }

  async manageDailySchedule(
    area_id: number | number[],
    type: ManageType,
    value: any,
    onDoneApi?: () => void
  ) {
    let areas: number[];
    if (Array.isArray(area_id)) areas = area_id;
    else areas = [area_id];
    if (areas.length === 0) {
      return;
    }
    const body: any = {};
    let playNow, needScheduled;
    if (value?.scheduleType) {
      playNow = value.scheduleType.playNow;
      needScheduled = value.scheduleType.needScheduled;
    }
    switch (type) {
      case ManageType.MONITOR:
        body.area = areas;
        body.action = type;
        //agent 랑 통신
        break;
      case ManageType.PLAY:
      case ManageType.STOP:
        body.area = areas;
        body.action = type;
        break;
      // case ManageType.VOLUME:
      //   body.area = areas;
      //   body.action = type;
      //   body.volume = value;
      //   break;

      // case ManageType.MUTE:
      //   body.area = areas;
      //   body.action = type;
      //   body.mute = value;
      //   break;
      case ManageType.SCHEDULE:
        if (!playNow && !needScheduled) {
          onDoneApi && onDoneApi();
          return;
        }
        if (value.disabled) {
          body.action = "stop";
          body.area = areas;
        } else {
          body.area = areas;
          body.start = value.start;
          body.stop = value.stop;
          body.action = "schedule";
          body.play_now = playNow;
        }
        break;

      case ManageType.THEME:
        if (!playNow && !needScheduled) return;
        body.area = areas;
        body.theme = value.theme;
        body.action = "theme";
        body.play_now = playNow;
        break;
      default:
        break;
    }

    const res = await Api.create<{ code: number }>("/api-bgm/control/", body);
    console.log(res);
    if (res.code !== 500) {
      onDoneApi && onDoneApi();
    }
  }
}

export class GlobalModalController extends Controller {
  public modal = new BehaviorSubject<GlobalModalProps>({
    visible: false,
    title: "",
    content: "",
    confirmText: "확인",
    cancelText: "취소",
    onConfirm: undefined,
    onCancel: undefined,
  });

  public init() {
    this.modal.next({
      visible: false,
      title: "",
      content: "",
      confirmText: "확인",
      cancelText: "취소",
      onConfirm: undefined,
      onCancel: undefined,
    });
  }

  public open(modal: GlobalModalProps) {
    this.modal.next({ ...modal, visible: true });
  }
}

export class ScreenLockController extends Controller {
  public screenLockMode = new BehaviorSubject<boolean>(false);
  public indicatingLock = new BehaviorSubject<boolean>(false);

  public toggleScreenLockMode(isScreenLock: boolean) {
    this.screenLockMode.next(isScreenLock);
  }

  public indicateScreenLockMode(isIndicating: boolean) {
    this.indicatingLock.next(isIndicating);
  }
}
