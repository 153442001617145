import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../components/input";
import { SignupType, useRegisterForm } from "../form/register.form";
import style from "../styles/register.module.scss";
import { AccountController } from "../util/Controller";
import { useController } from "juice-frontend-core";
import { startTimer } from "../util/helper";
import Success from "../svg/success";
import { Button } from "../components/button";

export default function Register() {
  const {
    f: {
      formState: { errors, isValid },
      watch,
      getValues,
      setError,
      getFieldState,
      resetField,
      handleSubmit,
    },
    r,
  } = useRegisterForm();
  const ctrl = useController<AccountController>(AccountController);
  const navigate = useNavigate();

  const [certCode, setCertcode] = useState<string | null>(null);
  const [timer, setTimer] = useState<NodeJS.Timer | null>(null);
  const [isCodeSent, setIsCodeSent] = useState<boolean>(false);
  const [isSendingCertCode, setIsSendingCertCode] = useState<boolean>(false);
  const [isCertCodeConfirmed, setIsCertCodeConfirmed] = useState(false);
  const [isDisabledConfirmCode, setIsDisabledConfirmCode] =
    useState<boolean>(true);
  const [isDisableInputEmail, setIsDisableInputEmail] =
    useState<boolean>(false);
  const [isDoneRegister, SetisDoneRegister] = useState(false);

  const timerRef = useRef("03:00");
  const checkbtnRef = useRef(null);

  // submit 전 값 여부 및 타당성 확인
  const isSignupButtonActive = isValid && isCertCodeConfirmed;

  // 비밀번호, 비밀번호 확인 일치 여부
  const isPasswordConfirmed =
    getValues("password").length > 0 && watch("check") === watch("password");

  // 인증번호 받기 버튼 활성화 여부
  const isValidSendCode = !!watch("email") && !getFieldState("email").invalid;

  // 인증번호 확인 버튼 활성화 여부
  const isValidConfirmCode =
    !!watch("email_code") && !getFieldState("email_code").invalid && isCodeSent;

  const sendCertCodeEmail = async () => {
    // 기존 타이머 초기화
    if (timer) {
      timerRef.current = "03:00";
      clearInterval(timer);
      setTimer(null);
    }

    resetField("email_code");
    setIsSendingCertCode(true);
    setIsCertCodeConfirmed(false);
    try {
      const certCode = await ctrl.sendMail(
        getValues("email"),
        "서비스 이용을 위해 이메일 인증을 요청하셨습니다. \n아래의 인증번호를 인증번호 입력창에 입력해주세요.",
        () => {
          resetField("email_code");
          setIsSendingCertCode(false);
          setIsCodeSent(false);
        }
      );
      if (certCode) {
        setIsCodeSent(true);
        setIsDisabledConfirmCode(false);
        setCertcode(certCode);
      }
      const jsTimer = startTimer(181, timerRef, checkbtnRef, () => {
        // 입력 시간 초과 시
        setError("email_code", {
          message: " 입력시간이 초과되었습니다.",
          type: "timeout",
        });
        resetField("email_code", { keepError: true });
        setIsCodeSent(false);
        setCertcode("");
        setIsDisabledConfirmCode(true);
      });
      setIsSendingCertCode(false);
      setTimer(jsTimer);
    } catch (err) {
      if (err === "not registered") {
        window.alert("등록되지 않은 사용자입니다. 관리자에게 문의해 주세요.");
      }
    }
  };

  const checkCertCode = () => {
    if (getValues().email_code.trim() === certCode && timer) {
      clearInterval(timer);
      setIsCertCodeConfirmed(true);
      setIsDisabledConfirmCode(true);
      setIsDisableInputEmail(true);
    } else {
      setError("email_code", {
        message: "일치하지 않습니다.",
      });
      resetField("email_code", { keepError: true });
    }
  };

  const onSubmit = handleSubmit(async (data: SignupType) => {
    const res = await ctrl.register(data.email, data.password);
    if (res) {
      SetisDoneRegister(true);
      navigate("/register?success=true");
    }
    timerRef.current = "03:00";
  });

  // 인증번호 받기 버튼
  function SendCertCodeBtn() {
    return (
      <button
        className={
          isSendingCertCode || isCertCodeConfirmed
            ? `${style.validateBtn} ${style.sent}`
            : isValidSendCode
            ? style.validateBtn
            : `${style.validateBtn} ${style.sent}`
        }
        disabled={
          isSendingCertCode || isCodeSent || isCertCodeConfirmed
            ? true
            : isValidSendCode
            ? false
            : true
        }
        onClick={sendCertCodeEmail}
      >
        {isSendingCertCode
          ? "전송중"
          : isCodeSent
          ? "전송완료"
          : errors?.email_code?.type === "timeout"
          ? "재전송"
          : "인증번호 받기"}
      </button>
    );
  }

  // 인증번호 확인 버튼
  function ConfirmCertCodeBtn() {
    return (
      <div className={style.validateTimer}>
        <div
          className={
            errors.email_code?.type === "timeout"
              ? `${style.timer} ${style.timeExpire}`
              : style.timer
          }
          ref={checkbtnRef}
        >
          {!isCertCodeConfirmed && <span>{timerRef.current}</span>}
        </div>
        <button
          onClick={() => checkCertCode()}
          disabled={
            !(isCertCodeConfirmed ? false : isValidConfirmCode ? true : false)
          }
        >
          {isCertCodeConfirmed ? "인증 완료" : "인증 확인"}
        </button>
      </div>
    );
  }

  return (
    <div className={style.registerContainer}>
      {isDoneRegister ? (
        <div className={style.doneForm}>
          <img
            src="/images/done_register.png"
            alt="done"
          />
          <strong>회원가입을 축하드립니다.</strong>
          <Button
            type="confirm"
            confirmText="로그인 하러가기"
            onClick={() => navigate("/")}
            W="100%"
            background="#0096FF"
          />
        </div>
      ) : (
        <form
          onSubmit={onSubmit}
          className={style.registerForm}
        >
          <h1>회원가입</h1>
          <div className={style.inputEmail}>
            <Input
              register={r.email}
              title={"이메일"}
              placeholder="이메일 입력"
              ele={<SendCertCodeBtn />}
              error={errors.email?.message}
              disabled={isDisableInputEmail}
              id="username"
            />
          </div>
          <div className={`${style.validateForm}`}>
            <Input
              disabled={isDisabledConfirmCode}
              register={r.email_code}
              title=""
              placeholder="인증번호 입력"
              error={errors.email_code?.message}
              ele={<ConfirmCertCodeBtn />}
            />
          </div>
          <div className={style.inputPw}>
            <Input
              register={r.password}
              title={"비밀번호"}
              placeholder={"8자 이상 문자/숫자/기호 사용"}
              error={errors.password?.message}
              isPassword={true}
              ele={
                <div
                  className={
                    !errors.password && getValues("password") !== ""
                      ? `${style.successContainer} ${style.success}`
                      : style.successContainer
                  }
                >
                  {!errors.password && getValues("password") !== "" ? (
                    <span className={style.success}>안전</span>
                  ) : (
                    <></>
                  )}
                  <Success
                    fill={
                      errors.password || getValues("password") === ""
                        ? "#C2C2C2"
                        : "#0096ff"
                    }
                  />
                </div>
              }
            />
          </div>
          <div className={`${style.validateForm} ${style.inputPwCheck}`}>
            <Input
              register={r.check}
              title={"비밀번호 확인"}
              placeholder="비밀번호 확인"
              error={errors.check?.message}
              disabled={
                getFieldState("password").invalid || watch("password") === ""
              }
              isPassword={true}
              ele={
                <div
                  className={
                    isPasswordConfirmed
                      ? `${style.successContainer} ${style.success}`
                      : style.successContainer
                  }
                >
                  <span className={style.success}>
                    {isPasswordConfirmed ? "일치" : ""}
                  </span>
                  <Success
                    fill={
                      errors.check || getValues("check") === ""
                        ? "#C2C2C2"
                        : "#0096ff"
                    }
                  />
                </div>
              }
            />
          </div>
          <button
            className={style.btn}
            type="submit"
            disabled={!isSignupButtonActive}
          >
            가입 완료
          </button>
        </form>
      )}
    </div>
  );
}
