import { useEffect, useState } from "react";
import { useController } from "juice-frontend-core";
import { GlobalModalController } from "../Controller";
import { GlobalModalProps } from "../../components/globalModal";

export default function useGlobalModal() {
  const ctrl = useController<GlobalModalController>(GlobalModalController);
  const [modal, setModal] = useState<GlobalModalProps>({
    visible: false,
    title: "",
    content: "",
    confirmText: "확인",
    cancelText: "취소",
    onConfirm: undefined,
    onCancel: undefined,
  });

  const openModal = (modal: GlobalModalProps) => {
    ctrl.open(modal);
  };

  const closeModal = () => {
    ctrl.init();
  };

  useEffect(() => {
    const sub = ctrl.modal.subscribe((modal) => {
      setModal(modal);
    });

    return () => {
      ctrl.init();
      sub.unsubscribe();
    };
  }, [ctrl]);

  return { modal, closeModal, openModal };
}
