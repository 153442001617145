import React from "react";

export default function Logo() {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2789_24031)">
        <path
          d="M9.57264 14.3097L16.4778 5.97785H12.7133C11.0225 5.97785 9.86519 6.48409 8.84124 7.6611L5.20579 12.0443V0H1.55313C0.696974 0 0 0.683424 0 1.52716V24H3.64836C4.50882 24 5.20149 23.3166 5.20149 22.4728V16.495L8.15718 20.0513L10.6697 23.0846C11.1774 23.7089 11.8443 23.9958 12.5326 23.9958H17.6223L14.331 20.0345L9.57694 14.3013L9.57264 14.3097ZM25.5342 19.7602C24.7641 19.7602 24.1403 19.1443 24.1403 18.3892V0H20.4919C19.6314 0 18.9345 0.683424 18.9345 1.52294V19.3468C18.9345 22.1016 20.8748 24 23.6842 24H27.6897C28.5501 24 29.2471 23.3166 29.2471 22.4728V19.756H25.5299L25.5342 19.7602Z"
          fill="white"
        />
        <path
          d="M5.69629 11.4325L5.70489 11.4241C5.70489 11.4241 5.69629 11.4283 5.69629 11.4325ZM29.9957 8.03652V4.64893L29.6731 4.9822C29.6602 4.98642 28.6835 5.96515 25.8784 5.96515H12.6187C10.5235 5.96515 9.53395 6.80045 8.78535 7.70746L8.37663 8.20104C8.37663 8.20104 8.35081 8.23058 8.33791 8.24323L5.70489 11.4199C6.66431 10.7112 7.97221 10.2851 9.31023 10.2851H25.8784C27.8876 10.2851 29.8193 9.70711 29.9785 8.23058C30 8.10823 29.9957 8.0323 29.9957 8.0323V8.03652Z"
          fill="#ED1C24"
        />
      </g>
      <defs>
        <clipPath id="clip0_2789_24031">
          <rect
            width="30"
            height="24"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
