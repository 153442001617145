/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { TimeRangeSelect } from "./timeRangeSelect";
import style from "../styles/manage-time-modal.module.scss";
import { Time, TimeRange } from "./timeRangeSelect";
import { Button } from "./button";
import { WarningSmallIcon } from "../svg/warningSmall";
import { useElapsedTimeCount } from "../util/hook/useElapsedTimeCount";
import { ManageTimeUpdateType, checkUpdateType } from "../pages/main";

interface Props {
  timeRange: TimeRange;
  closeModal: () => void;
  onConfirm: (date: TimeRange, updateType: ManageTimeUpdateType) => void;
}

export function ManageTimeModal({ timeRange, closeModal, onConfirm }: Props) {
  const [manageTime, setManageTime] = useState<TimeRange>({
    startAt: undefined,
    stopAt: undefined,
    disabled: false,
    area: undefined,
  });
  const handleOnSelectStartOption = (startAt?: Time, stopAt?: Time) => {
    setManageTime({
      ...manageTime,
      startAt: startAt,
      stopAt: stopAt,
    });
  };

  const handleOnSelectEndOption = (option?: Time) => {
    setManageTime({
      ...manageTime,
      stopAt: option,
    });
  };

  const handleOnToggleDisabled = (disabled: boolean) => {
    setManageTime({
      ...manageTime,
      disabled,
    });
  };

  const handleOnClickCancel = () => {
    closeModal();
  };

  const handleOnClickConfirm = () => {
    const { startAt, stopAt } = manageTime;
    const updateType = checkUpdateType(startAt!, stopAt!);
    onConfirm(manageTime, updateType);
    closeModal();
  };

  useEffect(() => {
    setManageTime({
      startAt: timeRange.startAt,
      stopAt: timeRange.stopAt,
      disabled: timeRange.disabled,
      area: timeRange.area,
    });
  }, [timeRange]);

  const [edited, setEdited] = useState(false);
  useEffect(() => {
    if (
      timeRange.startAt !== manageTime.startAt ||
      timeRange.stopAt !== manageTime.stopAt ||
      timeRange.disabled !== manageTime.disabled
    )
      setEdited(true);
    else setEdited(false);
  }, [manageTime]);
  return (
    <div className={style.background}>
      <div className={style.modal}>
        <div className={style.titleContainer}>운영시간 변경</div>
        <div className={style.timeRangeContainer}>
          <TimeRangeSelect
            rangeType={"today"}
            timeRange={timeRange}
            activeToggle={true}
            disabled={manageTime.disabled}
            onSelectStartOption={handleOnSelectStartOption}
            onSelectEndOption={handleOnSelectEndOption}
            onToggle={handleOnToggleDisabled}
            modal={true}
          />
        </div>
        <div className={style.instructionContainer}>
          <div className={style.icon}>
            <WarningSmallIcon />
          </div>
          현재시간 이후로 선택해주세요.
          <ElapsedTimeCount />
        </div>
        <div className={style.buttonContainer}>
          <Button
            W={200}
            H={56}
            type={"cancel"}
            onClick={handleOnClickCancel}
          />
          <Button
            W={200}
            H={56}
            active={edited}
            type={"confirm"}
            confirmText={"저장"}
            onClick={handleOnClickConfirm}
          />
        </div>
      </div>
    </div>
  );
}

const ElapsedTimeCount = () => {
  const elapsedTime = useElapsedTimeCount();
  return (
    <span className={style.time}>{`(현재 시간은 ${elapsedTime} 입니다.)`}</span>
  );
};
