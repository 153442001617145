import moment from "moment";

export const throttle = <T extends (...args: any[]) => void>(
  func: T,
  limit: number
) => {
  let inThrottle: boolean = false;

  return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
    if (!inThrottle) {
      func.apply(this, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

export const getWeather = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      async (pos) => {
        if (pos.coords) {
          const latitude = pos.coords.latitude;
          const longitude = pos.coords.longitude;

          const API_KEY = "9f54c6567a5f111cdd53a9c9f3c64506";
          const response = await fetch(
            `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${API_KEY}&units=metric`
          );
          if (response.ok) {
            const data = await response.json();
            resolve(data);
          } else {
            reject("날씨 정보를 가져올 수 없습니다.");
          }
        }
      },
      (e) => {
        reject("위치 정보를 가져올 수 없습니다.");
      }
    );
  });
};

export const translateDay = (day: string) => {
  switch (day) {
    case "Mon":
      return "월요일";
    case "Tue":
      return "화요일";
    case "Wed":
      return "수요일";
    case "Thu":
      return "목요일";
    case "Fri":
      return "금요일";
    case "Sat":
      return "토요일";
    case "Sun":
      return "일요일";
    default:
      return "월요일";
  }
};

export const translateDayWithDate = (date: Date) => {
  switch (moment(date).weekday()) {
    case 0:
      return "Sun";

    case 1:
      return "Mon";

    case 2:
      return "Tue";

    case 3:
      return "Wed";

    case 4:
      return "Thu";

    case 5:
      return "Fri";

    case 6:
      return "Sat";

    default:
      return "Mon";
  }
};

export const startTimer = (
  limit: number,
  timeRef: any,
  btnRef: any,
  onTimerExpired: undefined | ((value: boolean) => void)
) => {
  const now = new Date();

  // 현재시간과 limit초 이후의 값을 구한다.(타겟타임)
  const targetTime = moment(now).add(limit, "seconds");
  // 현지 시간과 타갯 타임의 차이를 구한다.
  let diff = targetTime.diff(moment());
  let remainTime = moment.utc(diff).format("HH:mm:ss");

  const settimer = setInterval(() => {
    // 1초마다 계속 차이를 구한다.
    diff = targetTime.diff(moment());
    remainTime = moment.utc(diff).format("mm:ss");

    if (diff >= 0) {
      // 남은 시간이 0보다 크면
      //setTime(remainTime);
      timeRef.current = remainTime;
      if (btnRef.current) {
        btnRef.current.innerText = remainTime;
      }
    }
    if (diff <= 0) {
      // 남은 시간이 0보다 작으면 끔
      clearInterval(settimer);
      onTimerExpired && onTimerExpired(true);
    }
  }, 1000);
  return settimer;
};

export const TIME_FULL_FORMAT = "YYYY-MM-DD[T]HH:mm:ss";

export const VALIDATE_EMAIL = /^([a-z0-9_.-]{1,})@([\da-z.-]+)\.([a-z.]{2,6})$/;

export const VALIDATE_PASSWORD =
  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;

export const FONT_STYLE =
  "bold 15px -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',sans-serif";

export function getMoment(
  HH: string | number,
  MM: string | number,
  SS: string | number
): moment.Moment {
  return moment()
    .hour(Number(HH))
    .minute(Number(MM))
    .second(Number(SS))
    .millisecond(0);
}

export function getMomentString(
  HH: string | number,
  MM: string | number,
  SS: string | number,
  format: string
) {
  const m = getMoment(HH, MM, SS);
  return m.format(format);
}
