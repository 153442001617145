interface Props {
  fill?: string;
}

export default function ArrowUp({ fill = "white" }: Props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="3 3 16 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5.75 12.5L10.75 6.25L15.75 12.5H5.75Z" />
    </svg>
  );
}
