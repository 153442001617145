import style from "../styles/player.module.scss";
import { DailyAreaScheduleModel } from "../util/Model";
import {
  AreaPlaybackState,
  Theme,
  ManageTimeUpdateType,
  checkUpdateType,
} from "../pages/main";
import DropDownMenu from "./dropDownMenu";
import { Button } from "./button";
import { ConfigIcon } from "../svg/config";
import { MonitorIcon } from "../svg/monitor";
import { ToggleButton } from "./toggleButton";
import { PlayIcon } from "../svg/play";
import { StopIcon } from "../svg/stop";
// import { VolumeSlider } from "./volumeSlider";
import { FONT_STYLE, getMoment, getMomentString } from "../util/helper";
import { useEffect, useRef, useState } from "react";
// import SoundMute from "../svg/SoundMute";

interface AreaPlayerProps {
  info: DailyAreaScheduleModel;
  currInfo?: AreaPlaybackState;
  onManage: () => void;
  onMonitor: () => void;
  isPlaying: boolean;
  isMonitoring: boolean;
  onPlay: (playing: boolean) => void;
  // onVolume: (vol: number) => void;
  themes: Theme[];
  onManageTime: (
    startAt: string,
    stopAt: string,
    area: number,
    disabled: boolean
  ) => void;
  disabled: boolean;
  onManageTheme: (theme: Theme, updateType: ManageTimeUpdateType) => void;
  isLock: boolean;
  sliderId: string;
}

export default function Player({
  info,
  currInfo,
  onManage,
  onMonitor,
  isPlaying,
  isMonitoring,
  onPlay,
  themes,
  onManageTime,
  disabled,
  onManageTheme,
  isLock,
  sliderId,
}: AreaPlayerProps) {
  const playerRef = useRef<HTMLDivElement>(null);
  const { area } = info;
  const [marqueeWidth, setMarqueeWidth] = useState(0);

  const ref = useRef<HTMLDivElement>(null);

  // const onRequsetVolume = (vol: number) => {
  //   onVolume(vol);
  // };

  const getChannels = (channels: string) => {
    const [ch1, ch2] = channels.split(",");
    return `CH.${ch1}-${ch2}`;
  };

  const getThemeItem = () => {
    const theme = themes.find((theme) => theme.title === info.theme);
    if (theme) {
      return theme;
    } else {
      return {
        id: 0,
        title: "",
        value: "",
      };
    }
  };

  const handleOnManageTimeModal = () => {
    const { start_at, stop_at, area } = info;
    onManageTime(start_at, stop_at, area.id, disabled);
  };

  const getTimeFormat = (time: string) => {
    const [HH, MM] = time.split(":");
    const timeFormat = getMomentString(HH, MM, 0, "A h:mm");
    return timeFormat;
  };

  const getTime = (time: string) => {
    const [HH, MM] = time.split(":");
    return getMoment(HH, MM, 0);
  };

  const handleOnManageTheme = (theme: Theme) => {
    const startAt = getTime(info.start_at);
    const stopAt = getTime(info.stop_at);
    const updateType = checkUpdateType(startAt, stopAt);
    onManageTheme(theme, updateType);
  };

  const handleOnPlay = () => {
    if (!disabled) {
      onPlay(!isPlaying);
    }
  };

  const getTextWidth = (text?: string) => {
    // canvas 요소를 메모리 상에서만 생성
    let canvas = document.createElement("canvas");
    let context = canvas.getContext("2d");
    if (context) {
      context.font = FONT_STYLE;
      const metrics = context.measureText(text ?? PLACEHOLDER);
      const marqueeWidth = 2 * (metrics.width + 20);
      return marqueeWidth;
    }
  };

  const playTitleAnim = () => {
    let width = getTextWidth(currInfo?.song) ?? 320;

    if (width <= 320) width = 320;
    setMarqueeWidth(width);
    if (!ref.current?.style) return;
    ref.current!.style.animation = "none";
    void ref.current?.offsetWidth;
    ref.current!.style.animation = `marquee${currInfo?.area} ${
      width / 32
    }s linear infinite`;
  };

  useEffect(() => {
    if (isPlaying) {
      playTitleAnim();
    }
  }, [currInfo?.song]);

  let keyframesStyle = null;
  if (marqueeWidth > 0) {
    keyframesStyle = `
    @keyframes marquee${currInfo?.area} {
      0% {
        left: 0;
      }
      100% {
        left: -${marqueeWidth / 2}px;
      }
    }
  `;
  }

  const marqueeSpan =
    marqueeWidth > 0 ? { width: marqueeWidth / 2 } : { width: "auto" };

  const startAtString = getTimeFormat(info.start_at);
  const stopAtString = getTimeFormat(info.stop_at);

  const PLACEHOLDER = " 현재 재생중인 곡이 없습니다.";
  const NODATEPLACEHOLDER = "오늘 일정이 없습니다.";

  return (
    <div
      className={style.playerContainer}
      ref={playerRef}
    >
      <style>{keyframesStyle}</style>
      <div className={style.areaBox}>
        <div className={style.toggleBtn}>
          <ToggleButton
            disabled={true}
            active={isMonitoring}
            onClick={onMonitor}
            icon={() => (
              <MonitorIcon fill={isMonitoring ? "#0096FF" : "#FFFFFF"} />
            )}
            defaultStyle={{
              backgroundColor: "#424242",
              border: "1px solid #424242",
            }}
            activeStyle={{
              backgroundColor: "#040404",
              border: "1px solid #0096FF",
            }}
            disabledStyle={{
              backgroundColor: "#424242",
              border: "1px solid #424242",
            }}
          />
        </div>
        <div className={style.location}>
          <div className={style.channelText}>{getChannels(area.channel)}</div>
          <div className={style.areaText}>{area.title}</div>
        </div>
      </div>
      <div className={style.separator} />
      <div className={style.playToggleBtn}>
        <ToggleButton
          disabled={disabled}
          icon={
            disabled
              ? () => <PlayIcon fill={"#616161"} />
              : !isPlaying
              ? () => <PlayIcon fill={"#0096FF"} />
              : StopIcon
          }
          active={!isPlaying}
          defaultStyle={{
            backgroundColor: "#0096FF",
            borderRadius: 38,
            background:
              "linear-gradient(213deg, #67C6FF 11.35%, #009FFF 86.53%)",
          }}
          activeStyle={{
            backgroundColor: "#040404",
            borderRadius: 38,
            border: "solid 1px #0096FF",
          }}
          disabledStyle={{
            borderRadius: 38,
            border: "solid 1px #616161",
          }}
          onClick={handleOnPlay}
        />
      </div>
      {isPlaying && !disabled ? (
        <>
          {currInfo?.song ? (
            <div className={style.marquee}>
              <div ref={ref}>
                <span style={marqueeSpan}>{currInfo?.song ?? PLACEHOLDER}</span>
                <span style={marqueeSpan}>{currInfo?.song ?? PLACEHOLDER}</span>
              </div>
            </div>
          ) : (
            <div className={style.songTitleBox}>{PLACEHOLDER}</div>
          )}
        </>
      ) : (
        <div className={style.songTitleBox}>
          {disabled ? NODATEPLACEHOLDER : PLACEHOLDER}
        </div>
      )}
      <div className={style.separator} />
      <DropDownMenu
        currOption={getThemeItem()}
        options={themes}
        onSelectOption={handleOnManageTheme}
        transparent={true}
        label
        labelText={"큐레이션"}
        disabled={false}
        parentRef={playerRef}
        border={false}
      />
      <div className={style.separator} />
      <div className={style.manageTimeBox}>
        <div
          className={style.timeText}
          style={{
            color: disabled ? "#424242" : "#FFFFFF",
          }}
        >
          <div className={style.textContainer}>
            <div className={style.labelContainer}>운영시간</div>
            <div className={style.timeInfoContainer}>
              <div
                className={style.text}
                style={{
                  color: disabled ? "#424242" : "#FFFFFF",
                }}
              >
                {`${startAtString} - ${stopAtString}`}
              </div>
              <div
                className={style.icon}
                onClick={handleOnManageTimeModal}
              >
                <ConfigIcon />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.manageAreaBox}>
        <Button
          onClick={onManage}
          W={84}
          H={38}
          confirmText={"공간관리"}
          active={!isLock}
        />
      </div>
    </div>
  );
}
