import { useEffect } from "react";
import style from "../styles/toastbar.module.scss";

export default function ToastBar({
  isOpen,
  closeModal,
  content,
}: {
  isOpen: boolean;
  closeModal: () => void;
  content: string;
}) {
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        closeModal();
      }, 1500);
    }
  }, [closeModal, isOpen]);

  return (
    <div
      style={{ bottom: !isOpen ? "-50px" : "20px" }}
      className={style.toastContainer}>
      <img src={"/images/check.png"} alt="check" />
      <span>{content}</span>
    </div>
  );
}
