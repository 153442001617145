import style from "../styles/global-modal.module.scss";
import { Button } from "./button";
import { WarningIcon } from "../svg/warning";
import useGlobalModal from "../util/hook/useGlobalModal";

export type GlobalModalProps = {
  visible?: boolean;
  title: string;
  content?: string;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export function GlobalModal() {
  const { modal, closeModal } = useGlobalModal();

  const {
    visible,
    title,
    content,
    confirmText,
    cancelText,
    onConfirm,
    onCancel,
  } = modal;

  const handleOnClickCancel = () => {
    onCancel && onCancel();
    closeModal();
  };

  const handleOnClickConfirm = () => {
    onConfirm && onConfirm();
    closeModal();
  };

  if (!visible) {
    return <></>;
  }

  return (
    <div className={style.background}>
      <div className={style.modal}>
        <div className={style.icon}>
          <WarningIcon />
        </div>
        <div className={style.titleContainer}>{title}</div>
        <div className={style.contentContainer}>{content}</div>
        <div className={style.buttonContainer}>
          {onCancel && (
            <Button
              type={"cancel"}
              W={200}
              H={56}
              onClick={handleOnClickCancel}
              cancelText={cancelText}
            />
          )}
          <Button
            type={"confirm"}
            W={200}
            H={56}
            onClick={handleOnClickConfirm}
            confirmText={confirmText}
          />
        </div>
      </div>
    </div>
  );
}
