import { useForm } from "react-hook-form";
import { VALIDATE_EMAIL, VALIDATE_PASSWORD } from "../util/helper";

export type FindPasswordType = {
  email: string;
  email_code: string;
  password: string;
  check: string;
};

export const useFindPassword = () => {
  const f = useForm<FindPasswordType>({
    mode: "onChange",
    defaultValues: {
      email: "",
      email_code: "",
      password: "",
      check: "",
    },
  });

  const r = {
    email: f.register("email", {
      required: true,
      pattern: {
        value: VALIDATE_EMAIL,
        message: "유효하지 않은 이메일 입니다. 다시 확인해주세요.",
      },
    }),
    email_code: f.register("email_code", {
      required: true,
      maxLength: 10,
      minLength: 6,
    }),
    password: f.register("password", {
      required: true,
      pattern: {
        value: VALIDATE_PASSWORD,
        message: "8자 이상, 문자/숫자/기호 사용",
      },
      onChange() {
        const value = f.getValues("password");
        if (value.length === 0) f.resetField("check");
        if (value.length > 20) {
          f.setValue("password", value.slice(0, 20));
        }
      },
    }),
    check: f.register("check", {
      required: true,
      onChange() {
        const value = f.getValues("check");
        if (value.length > 20) {
          f.setValue("check", value.slice(0, 20));
        }
      },
    }),
  };

  return { f, r };
};
