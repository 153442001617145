import style from "../styles/dropdown-menu.module.scss";
import { useState, useEffect, useRef } from "react";
import { useClickOutside } from "../util/hook/useClickOutsde";
import ArrowUp from "../svg/arrowUp";
import ArrowDown from "../svg/arrowDown";

type Option = {
  id: number;
  title: string;
  value: string;
};

interface Props {
  currOption: Option;
  options: Option[];
  onSelectOption: (options: Option) => void;
  disabled?: boolean;
  icon?: () => JSX.Element;
  transparent?: boolean;
  label?: boolean;
  labelText?: string;
  parentRef?: React.RefObject<HTMLDivElement> | null;
  border?: boolean;
}

export default function DropDownMenu({
  currOption,
  options,
  onSelectOption,
  disabled = false,
  icon,
  transparent = false,
  label,
  labelText,
  parentRef = null,
  border = true,
}: Props) {
  const menuRef = useRef<HTMLDivElement>(null);
  const [collapsed, setCollpased] = useClickOutside(menuRef);
  const [selectedOption, setSelectedOption] = useState<Option>({
    id: 0,
    title: "",
    value: "",
  });
  const [isMenuDropDown, setIsMenuDropDown] = useState<boolean>(true);

  const checkIsDropDownMenuDirection = () => {
    const EXPANDED_MENU_MAX_HEIGHT = 256;
    if (parentRef) {
      const top = parentRef.current?.getBoundingClientRect().top ?? 0;
      const height = parentRef.current?.offsetHeight ?? 0;
      const bottom = window.innerHeight - Math.floor(top) - Math.floor(height);
      const isMenuDropDown = bottom <= EXPANDED_MENU_MAX_HEIGHT;
      setIsMenuDropDown(!isMenuDropDown);
    }
  };

  const handleOnExpandMenu = () => {
    checkIsDropDownMenuDirection();
    setCollpased(!collapsed);
  };

  const handleOnSelectOption = (option: Option) => {
    if (option.id !== currOption.id) {
      setSelectedOption(option);
      onSelectOption(option);
    }
    setCollpased(true);
  };

  useEffect(() => {
    setSelectedOption(currOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currOption]);

  const disabledColor = disabled ? "#616161" : "#FFFFFF";

  return (
    <div
      ref={menuRef}
      className={style.container}
    >
      <div
        className={style.selected}
        onClick={handleOnExpandMenu}
        style={{
          pointerEvents: disabled ? "none" : "auto",
          backgroundColor: transparent ? "transparent" : "#424242",
          width: icon ? 113 : 100,
          border: border ? "1px solid #616161" : "none",
        }}
      >
        {icon && <div className={style.icon}>{icon()}</div>}
        <div className={style.titleContainer}>
          {label && <div className={style.label}>{labelText}</div>}
          <div
            className={style.title}
            style={{
              color: disabled ? "#616161" : "#FFFFFF",
            }}
          >
            {selectedOption.title}
          </div>
        </div>
        <div
          className={style.arrowIcon}
          style={{
            top: label ? 22 : "unset",
          }}
        >
          {collapsed ? (
            <ArrowDown fill={disabledColor} />
          ) : (
            <ArrowUp fill={disabledColor} />
          )}
        </div>
      </div>
      {!collapsed ? (
        <div
          className={style.expanded}
          style={{
            top: isMenuDropDown ? 43.25 : -262,
          }}
        >
          {options.map((option) => {
            return (
              <div
                key={option.id}
                onClick={() => handleOnSelectOption(option)}
                className={style.option}
                style={{
                  color: currOption.id === option.id ? "#616161" : "unset",
                }}
              >
                {option.title}
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
