interface Props {
  fill?: string;
}

export function MonitorIcon({ fill = "white" }: Props) {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill={fill}
    >
      <g clipPath="url(#clip0_2362_1067)">
        <path d="M9.71427 7.14285C10.6571 6.19999 10.6571 4.65713 9.71427 3.71427C8.77141 2.77141 7.22855 2.77141 6.28569 3.71427C5.34283 4.65713 5.34283 6.19999 6.28569 7.14285C7.22855 8.08571 8.77141 8.08571 9.71427 7.14285ZM6.85712 4.2857C7.48569 3.65712 8.51426 3.65712 9.14284 4.2857C9.77141 4.91427 9.77141 5.94285 9.14284 6.57142C8.51426 7.19999 7.48569 7.19999 6.85712 6.57142C6.22854 5.94285 6.22854 4.91427 6.85712 4.2857Z" />
        <path d="M1.71425 11.7143L2.28567 11.1429C5.42854 14.2857 10.5714 14.2857 13.7143 11.1429L14.2857 11.7143C10.8286 15.1714 5.1714 15.1714 1.71425 11.7143Z" />
        <path d="M3.99997 9.42857L4.5714 8.85714C6.45712 10.7429 9.54284 10.7429 11.4286 8.85714L12 9.42857C9.79998 11.6286 6.19997 11.6286 3.99997 9.42857Z" />
      </g>
      <defs>
        <clipPath id="clip0_2362_1067">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
