export default function Discard({ fill = "#D7506B" }) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 6H6V12H7V6Z"
        fill={fill}
      />
      <path
        d="M10 6H9V12H10V6Z"
        fill={fill}
      />
      <path
        d="M2 3V4H3V14C3 14.2652 3.10536 14.5196 3.29289 14.7071C3.48043 14.8946 3.73478 15 4 15H12C12.2652 15 12.5196 14.8946 12.7071 14.7071C12.8946 14.5196 13 14.2652 13 14V4H14V3H2ZM4 14V4H12V14H4Z"
        fill={fill}
      />
      <path
        d="M10 1H6V2H10V1Z"
        fill={fill}
      />
    </svg>
  );
}
