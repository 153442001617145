import {
  useState,
  useEffect,
  RefObject,
  Dispatch,
  SetStateAction,
} from "react";

export function useClickOutside(
  targetRef: RefObject<HTMLElement>
): [boolean, Dispatch<SetStateAction<boolean>>] {
  const [isClickOutside, setIsClickOutside] = useState<boolean>(true);

  const checkIsClickOutside = (e: MouseEvent) => {
    if (
      e.target instanceof HTMLElement &&
      targetRef.current &&
      !targetRef.current.contains(e.target)
    ) {
      setIsClickOutside(true);
    }
  };

  useEffect(() => {
    document.addEventListener("click", checkIsClickOutside);
    return () => document.removeEventListener("click", checkIsClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [isClickOutside, setIsClickOutside];
}
