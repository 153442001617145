interface Props {
  fill?: string;
}

export function TimerIcon({ fill = "white" }: Props) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.7498 17.4996C9.39002 17.4996 8.06081 17.0964 6.93023 16.341C5.79964 15.5855 4.91845 14.5118 4.3981 13.2556C3.87775 11.9993 3.7416 10.617 4.00687 9.28336C4.27215 7.94974 4.92693 6.72474 5.88841 5.76325C6.8499 4.80176 8.07491 4.14698 9.40852 3.88171C10.7421 3.61644 12.1245 3.75258 13.3807 4.27294C14.637 4.79329 15.7107 5.67447 16.4661 6.80506C17.2216 7.93565 17.6248 9.26486 17.6248 10.6246C17.6248 12.448 16.9004 14.1967 15.6111 15.486C14.3218 16.7753 12.5731 17.4996 10.7498 17.4996ZM10.7498 4.99961C9.63725 4.99961 8.54971 5.32951 7.62469 5.94759C6.69966 6.56567 5.97869 7.44418 5.55295 8.47201C5.12721 9.49985 5.01581 10.6308 5.23285 11.722C5.4499 12.8131 5.98562 13.8154 6.77229 14.6021C7.55897 15.3888 8.56124 15.9245 9.65239 16.1415C10.7435 16.3586 11.8745 16.2472 12.9024 15.8214C13.9302 15.3957 14.8087 14.6747 15.4268 13.7497C16.0449 12.8247 16.3748 11.7371 16.3748 10.6246C16.3748 9.13277 15.7821 7.70202 14.7272 6.64713C13.6724 5.59224 12.2416 4.99961 10.7498 4.99961Z" />
      <path d="M12.3685 13.1246L10.1248 10.8809V6.87461H11.3748V10.3621L13.2498 12.2434L12.3685 13.1246Z" />
      <path d="M5.4883 2.50158L3.25 4.74457L4.13481 5.62753L6.37311 3.38454L5.4883 2.50158Z" />
      <path d="M16.0092 2.49609L15.1244 3.37905L17.3627 5.62204L18.2475 4.73908L16.0092 2.49609Z" />
    </svg>
  );
}
