import { Api } from "juice-frontend-core";
import Logo from "../svg/logo";
import { useNavigate, useLocation, Link } from "react-router-dom";
import style from "../styles/header.module.scss";
import useLockScreen from "../util/hook/useLockScreen";
import { useEffect, useState } from "react";
import Anonymous from "../svg/anonymouse";
import useGlobalModal from "../util/hook/useGlobalModal";

export default function Header() {
  const { isScreenLockMode } = useLockScreen();
  const location = useLocation();
  const [curLocation, setCurLocation] = useState<string | undefined>(undefined);
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    const profile = localStorage.getItem("profile");
    if (profile) {
      const parse = JSON.parse(profile);
      if (parse && parse.location) setCurLocation(parse.location.title);
    }
  }, [location.pathname]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("success")) setIsSuccess(true);
    else setIsSuccess(false);
  }, [location.search]);

  return (
    <header
      className={style.headerContainer}
      style={{
        pointerEvents: isScreenLockMode ? "none" : "auto",
      }}
    >
      <Logo />
      {location.pathname === "/main" && (
        <div className={style.info}>
          {curLocation && (
            <>
              <div className={style.location}>
                <Anonymous />
                <span>{curLocation}</span>
              </div>
              <span className={style.separator} />
            </>
          )}
          <SignOut />
        </div>
      )}
      {location.pathname === "/register" && !isSuccess && (
        <Link
          className={style.login}
          to={"/"}
        >
          로그인
        </Link>
      )}
      {location.pathname === "/password" && !isSuccess && (
        <Link
          className={style.login}
          to={"/"}
        >
          로그인
        </Link>
      )}
    </header>
  );
}

function SignOut() {
  const { openModal } = useGlobalModal();

  const navigate = useNavigate();
  const signout = async () => {
    openModal({
      title: "로그아웃 하시겠습니까?",
      onConfirm: () => {
        Api.signOut();
        navigate("/", { replace: true });
      },
      onCancel: () => {},
      confirmText: "로그아웃",
    });
  };

  return (
    <div
      className={style.singout}
      onClick={signout}
    >
      로그아웃
    </div>
  );
}
