export default function Anonymous() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2708_12138)">
        <path
          d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12Z"
          fill="#75777B"
        />
        <path
          d="M12.0002 11.9998C13.611 11.9998 14.9168 10.694 14.9168 9.08317C14.9168 7.47234 13.611 6.1665 12.0002 6.1665C10.3893 6.1665 9.0835 7.47234 9.0835 9.08317C9.0835 10.694 10.3893 11.9998 12.0002 11.9998Z"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.0109 17.8333C17.0109 15.5758 14.7651 13.75 12.0001 13.75C9.23509 13.75 6.98926 15.5758 6.98926 17.8333"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2708_12138">
          <rect
            width="24"
            height="24"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
