export class Model {
  static API: string;
}
export interface ModelConstructor<T extends Model> {
  API: string;
  new (): T;
}

export class AuthUser extends Model {
  static readonly API = "/api-user/";

  id!: number;
  password!: string;
  last_login?: string;
  is_superuser?: boolean;
  username!: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  is_staff?: boolean;
  is_activate?: boolean;
  date_joined?: string;
}

export class Account<AuthUser = number, Company = number> extends Model {
  static readonly API = "/api-account/";
  id!: number;
  user!: AuthUser;
  is_manager!: boolean;
  company!: Company[];
}

export class Company extends Model {
  static readonly API = "/api-company/";
  id!: number;
  title!: string;
}

export class Location<Company = number> extends Model {
  static readonly API = "/api-location/";
  id!: number;
  title!: string;
  company?: Company;
  // areas?: Area[];
}

export class Area<Location = number> extends Model {
  static readonly API = "/api-area/";
  id!: number;
  title!: string;
  location!: Location;
  audio_channel?: string;
  device!: string;
  order!: number;
}

//기본 프리셋
export class GlobalAreaSchedule<
  Area = number,
  BgmTheme = number
> extends Model {
  static readonly API = "/api-global/area/schedule/";
  static readonly BULK_UPDATE_API = "/api-bulk_update/global/area/schedule/";
  id!: number;
  day!: string;
  volume!: number;
  theme!: BgmTheme;
  start_at!: string;
  stop_at!: string;
  area!: Area;
  disabled?: boolean;
}

//특정 일별 프리셋
export class EventAreaSchedule<Area = number, BgmTheme = number> extends Model {
  static readonly API = "/api-event/area/schedule/";
  static readonly BULK_UPDATE_API = "/api-bulk_update/event/area/schedule/";
  id!: number;
  date!: string;
  theme!: BgmTheme[];
  start_at!: string;
  stop_at!: string;
  area!: Area;
  disabled?: boolean;
}

export class DailyAreaSchedule<Area = number, BgmTheme = number> extends Model {
  static readonly API = "/api-daily/area/schedule/";
  static readonly BULK_UPDATE_API = "/api-bulk_update/daily/area/schedule/";
  id!: number;
  date!: string;
  is_day!: boolean;
  volume!: number;
  theme!: BgmTheme[];
  start_at!: string;
  stop_at!: string;
  area!: Area;
  disabled?: boolean;
  muted!: boolean;
}

export class BgmItem<BgmTheme = number, BgmTag = number> extends Model {
  static readonly API = "/api-bgm/item/";
  id!: number;
  manage_id!: string;
  length!: string;
  img_path?: string;
  title?: string;
  tempo!: number;
  composer?: string;
  producer?: string;
  voice?: boolean;
  theme?: BgmTheme[];
  // mood?: BgmMood[];
  // genre?: BgmGenre[];
  tag?: BgmTag[];
}

export class BgmTheme extends Model {
  static readonly API = "/api-bgm/theme/";
  id!: number;
  title?: string;
  title_en?: string;
}

// export class BgmGenre extends Model {
//   static readonly API = "/api-bgm/genre/";
//   id!: number;
//   title?: string;
//   title_en?: string;
// }

// export class BgmMood extends Model {
//   static readonly API = "/api-bgm/mood/";
//   id!: number;
//   title?: string;
//   title_en?: string;
// }

export class PlayedHistory<BgmItem = number, Area = number> extends Model {
  static readonly API = "/api-played/history/";
  id!: number;
  bgm!: BgmItem;
  area!: Area;
  played_at!: string;
}

export class BgmTag extends Model {
  static readonly API = "/api-bgm/tag/";
  id!: number;
  title?: string;
}
