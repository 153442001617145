/* eslint-disable react-hooks/exhaustive-deps */
import style from "../styles/dropdown-time-range-select.module.scss";
import { useEffect, useState } from "react";
import { ToggleSwitch } from "./toggleSwitch";
import moment from "moment";
import { DropDownTimeSelect } from "./dropDownTimeSelect";
import { TimerIcon } from "../svg/timer";
import { getMoment } from "../util/helper";

export type Time = moment.Moment;

export type Range = "today" | "all";

export type TimeRange = {
  area?: number;
  startAt?: moment.Moment;
  stopAt?: moment.Moment;
  disabled: boolean;
};

const getAvailableTimes = (fromTime?: moment.Moment) => {
  const times: moment.Moment[] = [];
  const HOURS = 24;
  const REPEAT_PER_HOUR = 6;
  const TOTAL_REPEAT = HOURS * REPEAT_PER_HOUR - 1;
  for (let i = 0; i <= TOTAL_REPEAT; i++) {
    const nextTime = getMoment(0, 0, 0).add(i === 0 ? 0 : 10 * i, "minutes");
    if (!fromTime) {
      times.push(nextTime);
    } else if (nextTime.isAfter(fromTime)) {
      times.push(nextTime);
    }
  }
  times[times.length] = getMoment(0, 0, 0).add(1, "days");
  return times;
};

interface TimeRangeSelectProps {
  timeRange: TimeRange;
  rangeType: "today" | "all";
  onSelectStartOption: (startAt?: Time, stopAt?: Time) => void;
  onSelectEndOption: (stopAt?: Time) => void;
  disabled?: boolean;
  activeToggle?: boolean;
  onToggle?: (disabled: boolean) => void;
  parentRef?: React.RefObject<HTMLDivElement> | null;
  modal?: boolean;
}

export function TimeRangeSelect({
  timeRange,
  rangeType,
  onSelectStartOption,
  onSelectEndOption,
  disabled = false,
  activeToggle = false,
  onToggle,
  parentRef = null,
  modal = false,
}: TimeRangeSelectProps) {
  const [currStartAt, setCurrStartAt] = useState<Time | undefined>(undefined);
  const [currStopAt, setCurrStopAt] = useState<Time | undefined>(undefined);
  const [startTimes, setStartTimes] = useState<moment.Moment[]>([]);
  const [stopTimes, setStopTimes] = useState<moment.Moment[]>([]);

  useEffect(() => {
    setCurrStartAt(timeRange.startAt);
    setCurrStopAt(timeRange.stopAt);
    // 선택 가능한 모든 시작 시간 리스트 생성
    setAvailableStartTimes();
    if (rangeType === "today") {
      const currTime = moment();
      const stopTime = currTime.isAfter(timeRange.startAt)
        ? currTime
        : timeRange.startAt?.clone();
      const stopTimes = getAvailableTimes(stopTime);
      setStopTimes(stopTimes);
    } else {
      // 시작 시간 이후의 선택 가능한 종료 시간 리스트 생성
      const stopTimes = getAvailableTimes(timeRange.startAt);
      setStopTimes(stopTimes);
    }
  }, [timeRange, rangeType]);

  const handleOnSelectStartTime = (startAt?: Time) => {
    if (
      startAt?.clone().startOf("day").isAfter(moment().clone().startOf("day"))
    ) {
      // 자정을 선택한 경우 전날 23:50 ~ 다음날 00:00 고정
      const startAt = getMoment(23, 50, 0);
      const stopAt = getMoment(24, 0, 0);
      setCurrStartAt(startAt);
      setCurrStopAt(stopAt);
      setStopTimes([stopAt]);
      onSelectStartOption(startAt, stopAt);
    } else if (startAt?.isSameOrAfter(currStopAt)) {
      // 선택한 시간이 종료 시간보다 이후인 경우
      const stopAt = startAt?.clone().add(10, "minutes");
      setCurrStopAt(stopAt);
      setStopTimes(getAvailableTimes(stopAt.clone()));
      onSelectStartOption(startAt, stopAt);
    } else {
      // 선택한 시간이 종료 시간보다 이전인 경우
      setStopTimes(getAvailableTimes(startAt?.clone()));
      onSelectStartOption(startAt, currStopAt);
    }
  };

  const handleOnSelectStopTime = (stopAt?: Time) => {
    setCurrStopAt(stopAt);
    onSelectEndOption(stopAt);
  };

  const setAvailableStartTimes = () => {
    const availableTimes = getAvailableTimes(
      rangeType === "today" ? moment() : undefined
    );
    setStartTimes(availableTimes);
  };

  const handleOnToggle = (disabled: boolean) => {
    onToggle && onToggle(disabled);
  };

  const disabledColor = disabled ? "#616161" : "#FFFFFF";

  return (
    <div
      className={`${style.container} ${modal ? style.modal : ""}`}
      style={{
        backgroundColor: disabled ? "#424242" : "#424242",
      }}
    >
      {activeToggle && onToggle ? (
        <ToggleSwitch
          isChecked={!disabled}
          onToggle={handleOnToggle}
        />
      ) : (
        <></>
      )}
      <div className={`${style.icon} ${modal ? style.modal : ""}`}>
        <TimerIcon fill={disabledColor} />
      </div>
      <DropDownTimeSelect
        type={"start"}
        option={currStartAt}
        options={startTimes}
        onSelectOption={handleOnSelectStartTime}
        disabled={disabled}
        updateAvailableTimes={setAvailableStartTimes}
        parentRef={parentRef}
        modal={modal}
      />
      <div
        className={`${style.separator} ${modal ? style.modal : ""}`}
        style={{
          backgroundColor: disabledColor,
        }}
      ></div>
      <DropDownTimeSelect
        type={"stop"}
        option={currStopAt}
        options={stopTimes}
        onSelectOption={handleOnSelectStopTime}
        disabled={disabled}
        parentRef={parentRef}
        modal={modal}
      />
    </div>
  );
}
