import { UIModel } from "juice-frontend-core";

export class AreaInfoModel extends UIModel {
  id = UIModel.MOCK_ID;
  //   location_id = UIModel.MOCK_ID;
  //   location_title = UIModel.MOCK_NAME;
  //   company_id = UIModel.MOCK_ID;
  //   company_title = UIModel.MOCK_NAME;
  title = UIModel.MOCK_NAME;
}

export class GlobalAreaSchedulModel extends UIModel {
  id = UIModel.MOCK_ID;
  day = UIModel.MOCK_NAME;
  volume = UIModel.MOCK_INTEGER;
  theme = [
    {
      id: UIModel.MOCK_ID,
      title: UIModel.MOCK_NAME,
      title_en: UIModel.MOCK_NAME,
    },
  ];
  start_at = UIModel.MOCK_NAME;
  stop_at = UIModel.MOCK_NAME;
  disabled = UIModel.MOCK_BOOL;
  area = UIModel.MOCK_ID;
}

export class DailyAreaScheduleModel extends UIModel {
  id = UIModel.MOCK_ID;
  date = UIModel.MOCK_DATE;
  volume = UIModel.MOCK_INTEGER;
  theme = UIModel.MOCK_NAME;
  start_at = UIModel.MOCK_NAME;
  stop_at = UIModel.MOCK_NAME;
  is_day = UIModel.MOCK_BOOL;
  area = {
    id: UIModel.MOCK_ID,
    title: UIModel.MOCK_NAME,
    channel: UIModel.MOCK_NAME,
    device: UIModel.MOCK_NAME,
    order: UIModel.MOCK_INTEGER,
    location: UIModel.MOCK_INTEGER,
  };
  disabled = UIModel.MOCK_BOOL;
  muted = UIModel.MOCK_BOOL;
}

export class EventAreaScheduleModel extends UIModel {
  id = UIModel.MOCK_ID;
  date = UIModel.MOCK_NAME;
  theme = UIModel.MOCK_ID;
  start_at = UIModel.MOCK_NAME;
  stop_at = UIModel.MOCK_NAME;
  is_day = UIModel.MOCK_BOOL;
  disabled = UIModel.MOCK_BOOL;
  area = UIModel.MOCK_ID;
}
